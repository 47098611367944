<template>
    <div>

    </div>
</template>
<script>
// apis 
import { queryContractTemplateCategory } from '@/api'
export default {
    data(){
        return {
            pageList: []
        }
    },
    mounted() {
        queryContractTemplateCategory()
    }

}
</script>