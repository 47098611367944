<template>
    <div class="legal-center">
        <a-tabs default-active-key="1" size="small" @change="changeTable">
            <a-tab-pane key="1" tab="消费记录" force-render></a-tab-pane>
            <a-tab-pane key="2" tab="订阅记录"></a-tab-pane>
        </a-tabs>
         <a-table :columns="columns" :data-source="tableData">
            <!-- <span slot="valid" slot-scope="valid,$item">
                <a-switch default-checked :checked="valid"  @change="changeChecked($item)" />
            </span> -->
            <span slot="status" slot-scope="status">
                <span>{{ status == 1 ? '正常消费' : '取消消费' }}</span>
            </span>
            <span slot="DYstatus" slot-scope="status">
                <span>{{ status == 1 ? '有效' : '退订' }}</span>
            </span>
            <span slot="dateEnd" slot-scope="dateEnd">
                <span>{{ dateEnd ? dateEnd : '不限' }}</span>
            </span>
            <span slot="itemInfoName" slot-scope="itemInfoName">
                <span>{{ itemInfoName.name || '--' }}</span>
            </span>
            <span slot="setInfo" slot-scope="setInfo">
                <!-- <div class="table-other">
                    <p>名称：{{ setInfo.name || '--' }}</p>
                    <p>描述：{{ setInfo.description || '--'}}</p>
                    <p>备注：{{ setInfo.remark || '--'}}</p>
                </div> -->
                    
                <span>{{ setInfo.name }}</span>
            </span>
            <!-- <span slot="action" slot-scope="data">
                <a-button type="primary" class="mr5" size="small" @click="clickView(data)">取消</a-button>
            </span> -->

            <!-- <span slot="action">
                <a>使用</a>
                <a-divider type="vertical" />
                <a>设置</a>
                <a-divider type="vertical" />
                <a>删除</a>
            </span> -->

        </a-table>
    </div>
</template>
<script>
import { queryConsumeRecord, queryHisSubscribe } from '@/api'

// 消费记录
let XFJV_columns = [{
    dataIndex: 'orderId',
    key: 'orderId', 
    title: '订单ID',
},{
    dataIndex: 'itemInfo',
    key: 'itemInfo',
    title: '权益名称',
    scopedSlots: { customRender: 'itemInfoName' },
},{
    dataIndex: 'status',
    key: 'status',
    title: '状态',
    scopedSlots: { customRender: 'status' },
},{
    dataIndex: 'consumeTm',
    key: 'consumeTm',
    title: '创建时间',
},{
    title: '消费数量',
    dataIndex: 'pointNum',
    key: 'pointNum',
},{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
}]


// 订阅记录
let DYJL_columns = [{
    dataIndex: 'id',
    key: 'id', 
    title: 'ID',
},{
    dataIndex: 'setInfo',
    key: 'setInfo',
    title: '套餐名称',
    scopedSlots: { customRender: 'setInfo' },
},{
    dataIndex: 'subscribeTm',
    key: 'subscribeTm',
    title: '订阅时间',
},{
    dataIndex: 'dateBegin',
    key: 'dateBegin',
    title: '生效时间',
},{
    dataIndex: 'dateEnd',
    key: 'dateEnd',
    title: '结束时间',
    scopedSlots: { customRender: 'dateEnd' },
},{
    dataIndex: 'status',
    key: 'status',
    title: '状态',
    scopedSlots: { customRender: 'DYstatus' },
}]
export default {
    data(){
        return {
            columns: XFJV_columns,
            tableData: []
        }
    },
    mounted(){
        this.RES_queryConsumeRecord()
        // this.RES_queryHisSubscribe()
        // queryHisSubscribe({
        //     data:{
        //         accountId: localStorage.getItem('orgAccountId')
        //     }
        // })
        // queryCurBenefit({
        //     data:{
        //         accountId: localStorage.getItem('orgAccountId')
        //     }
        // })
    },
    methods: {
        /** 查询消费记录 */
        RES_queryConsumeRecord(){
            queryConsumeRecord({
                data:{
                    accountId: localStorage.getItem('orgAccountId')
                }
            }).then(({ data })=>{
                this.tableData = data.data
            })
        },
        /** 查询订阅记录 */
        RES_queryHisSubscribe(){
            queryHisSubscribe({
                data:{
                    accountId: localStorage.getItem('orgAccountId')
                }
            }).then(({ data })=>{
                this.tableData = data.data
            })
        },
        changeTable(index){
            this.tableData = []
            if(index == 1){
                this.columns = XFJV_columns
                this.RES_queryConsumeRecord()
            }else{
                this.columns = DYJL_columns
                this.RES_queryHisSubscribe()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.legal-center {
    padding: 20px 20px;
}
.table-other {
    font-size: 12px;
    color: #ff8800; 
}
</style>