<template>
    <div class="manage">
        <p class="add-tpl">
            <a-button type="primary" class="mr10" @click="addTpl">新增模版</a-button>
        </p>

        <!-- <a-table :columns="columns" :data-source="pageList">
            <span slot="valid" slot-scope="valid,$item">
                <a-switch default-checked :checked="valid"  @change="changeChecked($item)" />
            </span>
            <span slot="action" slot-scope="data">
                <a-button type="primary" class="mr5" size="small" @click="clickView(data)"> 查看 </a-button>
                <a-button type="primary" size="small" @click="clickEdit(data)"> 编辑 </a-button>
            </span>
        </a-table> -->

        <div class="square" v-if="pageList.length > 0">
            <div class="item" v-for="(item,index) in pageList" :key="index">
                <img :src="item.iconUrl || mobanIco">
                <p class="title">{{ item.templateName }}</p>
                <p class="des">{{ item.templateDesc }}</p>
                <p class="info">
                    <span @click="clickView(item)"><a-icon type="fullscreen"/>查看</span>
                    <span @click="clickEdit(item)"><a-icon type="highlight"/>编辑</span>
                </p>
            </div>       
        </div>
        <div class="no-data" v-else>
            <a-empty :image="simpleImage" description="暂无数据"/>
        </div>
        
    </div>
</template>
<script>
import { queryPrivateContractTemplate, upDownContractTemplate } from '@/api'
import mobanIco from '@/assets/image/square/moban.webp'

import { Empty } from 'ant-design-vue';


export default {
    data(){
        return {
            // locale,
            mobanIco,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            columns: [{
                dataIndex: 'id',
                key: 'id', 
                title: 'ID',
            },{
                dataIndex: 'templateName',
                key: 'templateName',
                title: '合同名称',
            },{
                dataIndex: 'createTm',
                key: 'createTm',
                title: '创建时间',
            },{
                 title: '启用状态',
                dataIndex: 'valid',
                key: 'valid',
                scopedSlots: { customRender: 'valid' }
            },{
                title: '操作',
                key: 'action',
                scopedSlots: { customRender: 'action' },
            }
               
        ],
            pageList: []
        }
    },
    computed:{
		getOrg(){
			return this.$store.getters.getOrg
		} 
	},
    mounted(){
        
        // queryPublicContractTemplate({
        //     data:{
        //         accountId: localStorage.getItem('orgAccountId'),
        //     }
        // })
        this.resPageData()
    },
    methods:{
        // 点击搜索
        clickSearch(){
            console.log('sssssss')
        },
        // 点击查看
        clickView(item){
            this.$router.push({
                path: '/seal',
                query: {
                    type: 'view',
                    id: item.id,
                    //from: 'self', // 页面来源
                },
            })
            console.log(item)
        },
        // 点击编辑
        clickEdit(item){
            this.$router.push({
                path: '/work/sign',
                query: {
                    type: 'edit',
                    id: item.id,
                    name: item.templateName
                },
            })
        },
        addTpl(){
            this.$router.push({
                path: '/work/sign?from=addTpl'
            })
        },
        changeChecked(item){
            console.log(item)
            
            // let { selectOrg = {} } = this.$store.getters.getOrg || {}
            upDownContractTemplate({
                data: {
                    accountId: localStorage.getItem('orgAccountId'),
                    id: item.id,
                    valid: !item.valid
                }
            }).then(({ data })=>{
                if(data?.data){
                    item.valid = !item.valid
                }else{
                    this.$message.error('修改失败！请重试');
                }
            })
        },
        resPageData(searchText){
            let that = this
            // let { selectOrg = {} } = this.$store.getters.getOrg || {}
            if(localStorage.getItem('orgAccountId')){
                queryPrivateContractTemplate({
                    data:{
                        accountId: localStorage.getItem('orgAccountId'),
                        templateName: searchText || ''
                    }
                }).then(({ data })=>{
                    if(data?.data){
                        that.pageList = data?.data
                    }
                    console.log({data})
                })
            }
            
        }
    },
}
</script>
<style lang="less" scoped>
.manage {
    padding: 16px;
}
.add-tpl{
    padding-bottom: 20px;
    span {
        display: inline-block;
    }
}
.mr5 {
    margin-right: 5px;
}
</style>