<template>
    <div class="work">
        <div class="work-content">
            <div class="sig">
                <!-- <router-link tag="div" to="/work/sign">
                    <p><a-icon type="cloud-upload" />直接发起签署</p>
                </router-link> -->
                <!-- <div tag="div" class="select-tpl">
                    <p><a-icon type="qrcode" />发起合同</p>
                    <span>小程序端发起</span>
                </div> -->
                 <a-popover title="扫描二维码发起签署" placement="bottomRight">
                    <template slot="content" class="qr">
                        <div class="qr-content">
                            <img src="@/assets/image/QR.jpg"/>
                        </div>
                    </template>
                    <div tag="div" class="select-tpl">
                        <p><a-icon type="qrcode" />发起合同</p>
                        <span>小程序端发起</span>
                    </div>
                    <!-- <a-button type="primary" class="mr10">发起合同</a-button> -->
                </a-popover>
            </div>

            
            <div class="tpl-seal">
                <!-- 模版信息 -->
                <div class="statistics tpl">
                    <p class="title">模版信息
                        <router-link tag="span" to="/template/manage">更多<a-icon type="right" /></router-link>
                    </p>
                    <section>
                        <div v-for="(item,index) in tplList" :key="index" @click="clickViewTpl(item)">
                            <a-icon type="file-word" theme="filled" />
                            <p>{{ item.templateName }}</p>
                        </div>
                    </section>
                </div>
                 <!-- 印章信息 -->
                <div class="seal">
                    <p class="title">印章信息
                        <router-link tag="span" to="/my/seal">更多<a-icon type="right" /></router-link>
                    </p>
                    <div class="no-data yz-info">
                        <img v-for="(item,index) in sealInfo" :key="index" :src="item.imgBase64 | setImageData" alt="">
                    </div>
                </div>
            </div>
            


            <!-- 统计数据 -->
            <div class="statistics">
                <p class="title">统计数据
                    <!-- <span >更多<a-icon type="right" /></span> -->
                </p>
                <div class="no-data">暂无数据</div>
            </div>

            

        </div>
        <div class="work-right">
            <div class="user-info">
                <div class="info">
                    <img src="@/assets/image/index/tx.png"/>
                    <p>
                        <span class="name">欢迎您 : {{ $store.getters.getUserName || '--' }}</span>
                        <span>个人空间
                            <span class="real">已实名</span>
                        </span>
                    </p>
                </div>
                <p class="set-meal"><span>可签署份数：</span>{{ surplusNum === 0 ? '无限' : (surplusNum || '--') }}份</p>
                <router-link to="/home" class="user-center">用户中心<a-icon type="right" /></router-link>
            </div>
            <div class="help">
                <p class="title">使用帮助
                    <router-link tag="span" to="/my/help">更多<a-icon type="right" /></router-link>
                </p>
                <ul>
                    <li v-for="(item,index) in helpList" :key="index">{{ item.text }}<a-icon type="right" /></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// api

import { queryPrivateContractTemplate, querySealByOwner, queryCurBenefit } from '@/api'
// 账户信息，印章信息，套餐信息，使用帮助
export default {
    data(){
        return {
            tplList: [],
            sealInfo: [],
            inputDom: null,
            helpList:[{
                text: '如何进行个人实名认证？'
            },{
                text: '如何进行企业实名认证？'
            },{
                text: '电子合同的法律依据是什么？'
            },{
                text: '电子签名的实现原理是什么？'
            },{
                text: '企业用户如何快速上手盾约？'
            },{
                text: '如何创建企业印章?'
            },{
                text: '如何直接发起签署合同？'
            },{
                text: '如何进行合同审批？'
            },{
                text: '如何设置新增模板权限？'
            },{
                text: '如何购买签署合同套餐？'
            }],

            // 剩余次数 0:不限次数
            surplusNum: ''
        }
    },
    filters:{
        setImageData(data){
            if(data.indexOf('data:image/png;base64,')>= 0){
                return data
            }else{
                return `data:image/png;base64,${data}`
            }
        }
    },
    computed:{
        getOrg(){
			return this.$store.getters.getOrg
		}
    },
    mounted(){
        this.$nextTick(()=>{
            this.creatInput()
            this.RES_queryPrivateContractTemplate()
            this.RES_SelfPageData()
            this.RES_queryCurBenefit()
        })
    },
    methods:{
        /** 查询当前权益 */
        RES_queryCurBenefit(){
            queryCurBenefit({
                data:{
                    accountId: localStorage.getItem('orgAccountId'),
                }
            }).then(({ data })=>{
                this.surplusNum = data?.data?.surplusNum
            })
        },

        /** 查询合同模版 */
        RES_queryPrivateContractTemplate(){
            // let { selectOrg = {} } = this.getOrg || {}
            if(localStorage.getItem('orgAccountId')){
                queryPrivateContractTemplate({
                    data:{
                        accountId: localStorage.getItem('orgAccountId'),
                        templateName: ''
                    }
                }).then(({ data })=>{
                    if(data?.data){
                        this.tplList = data?.data
                    }
                    console.log(data)
                })
            }
        },
        // 点击查看
        clickViewTpl(item){
            this.$router.push({
                path: '/seal',
                query: {
                    type: 'view',
                    id: item.id
                },
            })
            console.log(item)
        },
        /** 查询个人印章 */
        RES_SelfPageData(){
            let { selectOrg = {} } = this.getOrg || {}
            let that = this
            if(selectOrg.orgId){
                querySealByOwner({
                    data:{
                        accountId: localStorage.getItem('accountId')
                    }
                }).then(({ data })=>{
                    if(data?.data){
                        that.sealInfo = data?.data || []
                    }
                    console.log(data)
                })
            }
        },
         /** 创建一个 input dom */
        creatInput() {
            const input = document.createElement('input')
            input.type = 'file'
            //input.accept = "image/*" // 只能选择图片类型
            this.inputDom = input
            input.addEventListener('change', () => {
                this.changeFile()
            })
        },
        changeFile(){
            //
        },
        upFile(){
            this.inputDom.click()
        }
    },
     watch:{
		getOrg(){
			this.RES_queryPrivateContractTemplate()
            this.RES_SelfPageData()
		}
	}
}
</script>
<style lang="less" scoped>
.work {
    width: 100%;
    height: 100%;
    background: rgb(245 246 247);
    display: flex;
    padding: 16px 24px 0px 24px;
    box-sizing: border-box;
    .work-content {
        width: 100%;
        margin-right: 16px;
    }
    .work-right {
        width: 334px;
        min-width: 334px;
    }
}
.statistics, .seal, .user-info, .help {
    background: #ffffff;
    padding: 16px 24px;
    margin-bottom: 16px;
    border-radius: 8px;
    &:hover {
        box-shadow: 0 0 8px #c4c4c4;
    }
   
    .title {
        font-size: 16px;
        font-weight: 500;
        color: #222;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
        span {
            font-size: 12px;
            color: #888;
        }
    }
}
.sig{
    display: flex; 
    margin-bottom: 30px;
    div {
        width: 200px;
        text-align: center;
        color: #fff;
        padding: 10px 0px;
        margin-right: 30px;
        border-radius: 8px;
        // background: linear-gradient(89.96deg,rgb(35 189 255) .03%,rgb(0 145 227) 99.96%);
        background: linear-gradient(45deg, #3fc7e9, #1349c3);
        &:hover {
            transition: all 0.2s ease-in;
            box-shadow: 0 0 5px #04498d;
        }
        p {
            width: 100%;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding-bottom: 5px;
            i {
                margin-right: 5px;
            }
        }
    }
}
.qr-content {
    text-align: center;
    img {
        width: 160px;
    }
}

.tpl {
    width: 100%;
    section {
        display: flex;
    }
    div {
        width: 100px;
        height: 120px;
        padding: 20px 5px;
        text-align: center;
        background: #ececec;
        border: 1px solid rgb(230 230 230);
        border-radius: 4px;
        margin-right: 20px;
        i {
            font-size: 40px;
        }
        p {
            padding-top: 5px;
            font-size: 14px;
        }
    }
}

.tpl-seal {
    display: flex;
    justify-content: space-between;
    .tpl, .seal {
        width: calc(50% - 5px);

    }
}

.no-data {
    width: 100%;
    height: 180px;
    line-height: 180px;
    text-align: center;
    padding-top: 0px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    color: #888;
}
.yz-info {
    img {
        height: 160px;
    }
}

.user-info {
    .info, .set-meal {
        box-shadow: inset 0 -1px 0 #f2f3f5;
    }
    .info {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        
        img {
            width: 40px;
            margin-right: 20px;
        }
        p {
            display: flex;
            flex-direction: column;
        }
        .name {
            font-size: 14px;
            font-weight: 500;
        }
        .real {
            font-size: 12px;
            padding: 2px 5px;
            background: rgb(50, 206, 141);
            margin-left: 5px;
            color: #fff;
            border-radius: 3px;
        }
    }
    .set-meal {
        padding: 16px 0px;
        span {
            color: #888;
        }
        a {
            color: #1063f1;
        }
    }
    .user-center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0px 0px 0px;
        color: #1063f1;
        i {
            font-size: 12px;
            font-weight: 500;
        }
    }
}
.help {
    ul {
        border-top: 1px solid #f2f3f5;
        padding: 8px 0px;
        li {
            padding: 10px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 8px;
                color: #888;
            }
            &:hover {
                color: #1063f1;
            }
        }
    }
}
</style>