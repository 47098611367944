<template>
  <div class="info-m">
    <div class="user-info">
      <div class="mask"></div>
      <img class="bg" src="@/assets/image/my/bg.jpg" alt="" srcset="" />
      <div class="name">
        <img class="tx-ico" src="@/assets/image/index/tx.png" />
        <h3>姓名：{{ name || "--" }}</h3>
      </div>

      <div :class="['right-m']">
        <div>
          <a class="btn rz" v-if="status == 2"
            ><a-icon type="safety-certificate" theme="filled" />已认证</a
          >
          <a class="btn rz no" v-else @click="dunyueerweima = true"
            ><a-icon type="safety-certificate" />未认证</a
          >
        </div>
        <h4>证件号：{{ idCard | idCardCalc }}</h4>
        <p>邮箱：{{ email || "--" }}</p>
        <p>手机号：{{ phone | phoneCalc }}</p>
        <a-button
          class="addUserInfo"
          type="primary"
          @click="openPopup(status)"
          >{{ status != 2 ? btnText : "填写企业信息完成企业认证" }}</a-button
        >
      </div>
    </div>

    <!-- <a-button v-if="status != 2" >立即认证</a-button> -->

    <a-modal
      :title="btnText"
      cancelText="取消"
      okText="保存"
      :visible="popEditInfo"
      @ok="saveInfo"
      @cancel="popEditInfo = false"
    >
      <a-form-model-item label="姓名">
        <a-input v-model="newName" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item label="身份证号">
        <a-input v-model="newIdCard" placeholder="请输入身份证号" />
      </a-form-model-item>
      <a-form-model-item label="手机号">
        <a-input v-model="newPhone" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="邮箱">
        <a-input v-model="newEmail" placeholder="请输入邮箱" />
      </a-form-model-item>
    </a-modal>

    <a-modal
      title="扫描二维码认证"
      :visible="dunyueerweima"
      cancelText=""
      okText=""
      width="200px"
      @ok="dunyueerweima = false"
      @cancel="dunyueerweima = false"
    >
      <img
        class="dunyue"
        src="../../../src/assets/image/dunyue.png"
        alt=""
        srcset=""
      />
    </a-modal>
  </div>
</template>
<script>
// api
import { updateUserInfo, addOrg } from "@/api";

export default {
  data() {
    return {
      name: "",
      idCard: "",
      phone: "",

      email: "",
      status: "",

      newName: "",
      newIdCard: "",
      newPhone: "",
      newEmail: "",

      dunyueerweima: false,

      popEditInfo: false,
    };
  },
  filters: {
    idCardCalc(text) {
      if (text) {
        let text1 = text.slice(0, 6);
        let text2 = text.slice(text.length - 4, text.length);
        return text1 + " **** **** " + text2;
      } else {
        return "**** **** ****";
      }
    },
    phoneCalc(text) {
      if (text) {
        let text1 = text.slice(0, 3);
        let text2 = text.slice(text.length - 4, text.length);
        return text1 + " **** " + text2;
      } else {
        return "*** **** ****";
      }
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.getUserInfo;
    },
    orgInfoList() {
      return this.$store.getters.orgInfoList;
    },
    btnText() {
      if (!(this.name || this.idCard)) {
        return "完善资料";
      } else {
        return "编辑资料";
      }
    },
  },
  mounted() {
    this.setPagedata(this.getUserInfo);
  },
  methods: {
    // 打开弹窗
    openPopup(status) {
      if (status == 2) {
        this.$emit("addEnterprise");
      } else {
        this.newName = this.name;
        this.newIdCard = this.idCard;
        this.newPhone = this.phone;
        this.newEmail = this.email;
        this.popEditInfo = true;
      }
    },

    // 保存用户信息
    saveInfo() {
      if (this.newName && this.newPhone && this.newEmail && this.newIdCard) {
        updateUserInfo({
          data: {
            userName: this.newName,
            mobile: this.newPhone,
            email: this.newEmail,
            idNumber: this.newIdCard,
            idType: "CRED_PSN_CH_IDCARD",
          },
        })
          .then(({ data }) => {
            if (data?.code == 1) {
              this.$message.success("编辑成功");
              this.name = this.newName;
              this.idCard = this.newIdCard;
              this.phone = this.newPhone;
              this.email = this.newEmail;

              let res = data?.data || {};
              //设置机构数据
              let storeData = {
                orgInfoList: res.orgInfoList || [],
              };
              this.$store.dispatch("setState", storeData);

              //设置个人数据
              localStorage.setItem("userId", res.userId || "");
              localStorage.setItem("accountId", res.accountId || "");
              // 存储用户信息
              this.$store.dispatch("setState", { userInfo: res || {} });
            } else {
              this.$message.error(data?.msg);
            }
          })
          .finally(() => {
            this.popEditInfo = false;
          });
      } else {
        this.$message.error("请填写全部的个人资料");
      }
    },
    // 点击添加企业
    addEnterprise() {
      this.isAddEPopup = true;
    },
    // 确认添加企业
    addOrg() {
      if (this.orgName && this.orgCode) {
        addOrg({
          data: {
            orgName: this.orgName,
            orgCode: this.orgCode,
          },
        })
          .then(({ data }) => {
            if (data?.data?.msg) {
              this.$message.error(data.data.msg);
            }
          })
          .finally(() => [(this.isAddEPopup = false)]);
      } else {
        this.$message.error("请填写完整的企业名称和信用代码");
      }
    },
    setPagedata(newDate) {
      this.name = newDate.userName;
      this.idCard = newDate.idNumber;
      this.phone = newDate.mobile;
      this.email = newDate.email;
      this.status = newDate.status;
    },
    upUserInfo(option) {
      updateUserInfo(option).then(({ data }) => {
        if (data?.data) {
          this.$message.success("修改成功");
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    //修改电话
    editPhone() {
      if (this.disabledPhone) {
        this.disabledPhone = false;
        this.upUserInfo({
          data: {
            mobile: this.phone,
          },
        });
      } else {
        this.disabledPhone = true;
      }
    },

    // 点击进入企业
    goOrg(item) {
      if (item.orgId == this.$store.getters.getOrgId) {
        this.$message.warning(`当前已选择${item.orgName}`);
      } else {
        this.$store.dispatch("setState", {
          selectOrg: item || {},
          OrgId: item.orgId || "",
        });
        this.$router.push({
          path: "/work",
        });
      }
    },

    // 修改邮箱
    editEmail() {
      if (this.disabledEmail) {
        this.disabledEmail = false;
        this.upUserInfo({
          data: {
            email: this.email,
          },
        });
      } else {
        this.disabledEmail = true;
      }
    },

    // 修改密码
    editPassword() {
      if (this.disabledPassword) {
        this.disabledPassword = false;
        //  this.upUserInfo()
      } else {
        this.disabledPassword = true;
      }
    },

    // 点击删除机构
    clickDEL(data) {
      console.log(data);
    },
  },
  watch: {
    getUserInfo(newDate) {
      this.setPagedata(newDate);
    },
  },
};
</script>
<style lang="less" scoped>
.dunyue {
  width: 160px;
}
.info-m {
  width: 100%;
  padding-top: 20px;
  // padding-left: 30px;
  text-align: center;

  h3 {
    padding: 10px 0px 5px 0px;
  }
}
.user-info {
  position: relative;
  height: 280px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #000;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .name {
    position: absolute;
    top: 30%;
    width: 100px;
    left: 10%;
    color: #fff;
    text-align: center;
    h3 {
      padding-top: 10px;
      color: #fff;
    }
    img {
      width: 60px;
    }
  }
  .right-m {
    width: 300px;
    position: absolute;
    top: 0%;
    right: 10%;
    padding-top: 60px;
    color: #fff;
    text-align: right;
    height: 100%;
    // backdrop-filter: saturate(100%) blur(10px);
    h4 {
      color: #fff;
    }
    .rz {
      color: #fff;
    }
  }
  .bg {
    width: auto;
    height: 320px;
    // filter: blur(10px);
    // opacity: 0.6;
  }
}
.addUserInfo {
  margin-top: 30px;
  margin-right: 10px;
}
</style>
