<template>
    <div class="input-box">
        <span class="label">{{ label }}</span>
        <input
            :type="inputType" 
            v-model="inputValue" 
            autocomplete="new-password" 
            :maxlength="maxlength" 
            @input="UPinput" 
            @blur="UPblur"
            :style="inputStyle"
        />
        <template v-if="type == 'password'">
            <img class="pwd-ico" v-if="!showPassword" @click="changePWD" :src="PWD_CLOSE"/>
            <img class="pwd-ico" v-else @click="changePWD" :src="PWD_OPEN"/>
        </template>
        <template>
            <slot name="right">
                <span v-if="rightText" class="right-text" @click="clickRightText">{{ rightText }}</span>
            </slot>
        </template>
        
    </div>
</template>
<script>
import  PWD_CLOSE from '@/assets/image/login/pwd_close.png'
import  PWD_OPEN from '@/assets/image/login/pwd_open.png'



export default {
    props:{
        /** 标签 */
        label:{
            type: String,
            default: () => {
                return ''
            }
        },
        /** 类型 */
        type: {
            type: String,
            default:()=>{
                return 'text'
            }   
        },
        value: {
            type: String || Number,
            default : ''
        },
        /** 格式化 */
        formart: {
            type: Function,
            default: null
        },
        /** 最大输入 */
        maxlength: {
            type: String || Number,
            default: '99'
        },

        /** 输入框右侧显示一些图标或者文字 */
        rightText: {
            type: String,
            default: ''
        },
        /** 初始化的时候是否显示密码 */
        initShowPwd: {
            type: Boolean,
            default: false
        },

        inputStyle: {
            type: Object,
            default(){
                return {}
            }
        }

    },
    computed: {
        inputType(){
            if(this.type == 'password'){
                if(this.showPassword){
                    return 'text'
                }else{
                    return this.type
                }
            }else{
                return this.type
            }
        }
    },
    data(){
        return {
            PWD_CLOSE,
            PWD_OPEN,
            inputValue: '',
            showPassword: false
        }
    },
    methods: {
        changePWD(){
            this.showPassword = !this.showPassword
        },
        /** 点击右侧的文字 */
        clickRightText(){
            this.$emit('rightText')
        },
        /** input */
        UPinput(){
            let val = this.inputValue
            if(this.formart){
                val = this.formart({
                    value: this.inputValue
                })
                this.inputValue = val
            }
            this.$emit('input',{
                value: val
            })
        },
        UPblur(){
            this.$emit('blur')
        }
    },
    watch:{
        value(){
            this.inputValue = this.value
        }
    }
}
</script>
<style lang="less" scoped>
.input-box {
    position: relative;
    width: 100%;
    height: 50px;
    .label {
        position: absolute;
        top: 14px;
        z-index: 999;
        left: 20px;
        color: #B5C4C5;
    }
    input {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        background: #F8FDFF;
        border: none;
        color: #004043;
        border-radius: 10px;
        padding: 16px 20px 16px 90px;
        font-size: 16px;
        line-height: 10px;
        height: 50px;
    }
    .pwd-ico {
        z-index: 999;
        position: absolute;
        top: 20px;
        right: 18px;
        width: 16px;
        height: auto;
    }
    .right-text {
        position: absolute;
        top: 14px;
        right: 10px;
        color: #018F8E;
        font-size: 16px;
    }
}
input:-webkit-autofill {
    -webkit-text-fill-color: #004043;
    -webkit-box-shadow: 0 0 0px 1000px #F8FDFF inset;
}
</style>