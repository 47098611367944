<template>
	<div class="contract">
		<div class="title">
            <a-button type="primary" class="mr10" @click="openInvitePopup = true">邀请员工</a-button>
		</div>
		<div>
			<a-table :columns="columns" :data-source="data">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<span slot="customTitle">姓名</span>

				<span slot="userStatus" slot-scope="userStatus">
					<a-tag :color="userStatus ? 'volcano' : 'green'"
					>{{ userStatus > 0 ? (userStatus == 1 ? '待认证' : '已认证') : '邀请中' }}</a-tag>
				</span>
				<span slot="status" slot-scope="status,data">
					<a-switch default-checked :checked="status===0"  @change="clickDJ(data)" />
				</span>

				<span slot="action" slot-scope="data">
					<a-button v-if="data.userStatus == 1" style="margin-left: 8px" type="primary" size="small" @click="clickDEL(data)">删除</a-button>
					<!-- <a-dropdown v-if="data.status > 0"> -->
						<!-- <a-menu slot="overlay"> -->
							<!-- <a-menu-item key="1" @click="clickDJ(data)">冻结</a-menu-item> -->
							<!-- <a-menu-item key="2" @click="clickDEL(data)">删除</a-menu-item> -->
							<!-- <a-menu-item key="1" @click="clickEDIT(data, 'edit')">编辑</a-menu-item> -->
							<!-- <a-menu-item key="2" @click="clickEDIT(data, 'view')">查看</a-menu-item> -->
						<!-- </a-menu> -->
						<!-- <a-button style="margin-left: 8px" type="primary" size="small"> -->
							<!-- 修改 -->
							<!-- <a-icon type="down" /> -->
						<!-- </a-button> -->
					<!-- </a-dropdown> -->
					<a-button v-else-if="data.userStatus == 0" style="margin-left: 8px" type="primary" size="small" @click="clickCancelInvite(data)">
						取消邀请
					</a-button>
				</span>
			</a-table>
		</div>

		<!-- 编辑查看员工弹窗 -->
		<a-modal
			:visible="viewOrEditPopup"
			cancelText="取消"
			okText="确认"
			@ok="InviteEmployeesOK"
			@cancel="viewOrEditPopup = false"
			width="50%"
		>
			<a-descriptions :title="popupType == 'view' ? '查看': '编辑'">
				<a-descriptions-item label="UserName">Zhou Maomao</a-descriptions-item>
				<a-descriptions-item label="Telephone">1810000000</a-descriptions-item>
				<a-descriptions-item label="Live">Hangzhou, Zhejiang</a-descriptions-item>
				<a-descriptions-item label="Remark">empty</a-descriptions-item>
				<a-descriptions-item
					label="Address"
				>No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China</a-descriptions-item>
			</a-descriptions>
		</a-modal>

		<!-- 邀请员工 -->
		<a-modal
			:visible="openInvitePopup"
			cancelText="取消"
			okText="邀请"
			title="邀请员工"
			@ok="openInviteOk"
			@cancel="openInvitePopup = false"
			width="28%"
			class="invite-popup"
		>	
			<a-input v-model="inv_phone" type="number" placeholder="请输入电话" />	
			<a-input v-model="inv_name" type="text" placeholder="请输入姓名" />	
		</a-modal>
	</div>
</template>
<script>
// api 
import { inviedUser, queryOrgUser ,cancelInvite, stopUser, delUser, activeUser } from '@/api'

export default {
	data() {
		return {
			columns:[{
				title: 'ID',
				dataIndex: 'id',
				key: 'id',
			},{
				title: '姓名',
				dataIndex: 'userName',
				key: 'userName',
			},{
				title: '创建时间',
				dataIndex: 'createTm',
				key: 'createTm',
			},{
				title: '电话',
				dataIndex: 'mobile',
				key: 'mobile',
			},{
				title: '用户状态',
				dataIndex: 'userStatus',
				key: 'userStatus',
				scopedSlots: { customRender: 'userStatus' }, // 0： 邀请中，1：待认证，2：已认证
			},{
                 title: '冻结',
                dataIndex: 'status',
                key: 'status',
                scopedSlots: { customRender: 'status' }
            },{
				title: '操作',
				key: 'action',
				scopedSlots: { customRender: 'action' },
			}],
			data: [],

			//邀请员工
			openInvitePopup: false,
			inv_phone: '',
			inv_name: '',

			//编辑或者查看
			popupType: 'view',  //view 查看｜ edit 编辑
			viewOrEditPopup: false,


			
		}
	},
	computed:{
		getOrg(){
			return this.$store.getters.getOrg
		} 
	},
	mounted(){
		this.$nextTick(()=>{
			this.resPageData()
		})
	},
	methods: {
		/** 请求页面列表信息 */
		resPageData(){
			let { selectOrg = {} } = this.getOrg || {}
			if(selectOrg.orgId){
				queryOrgUser({
					data: {
						orgId: selectOrg.orgId,
						accountId: localStorage.getItem('orgAccountId')
					}
				}).then(({ data })=>{
					let list = data?.data || []
					this.data = list
				})
			}

		},
		/** 点击取消邀请 */
		clickCancelInvite(data){
			console.log(data)
			let { selectOrg = {} } = this.getOrg || {}
			cancelInvite({
				data:{
					accountId: localStorage.getItem('orgAccountId'),
					orgId: selectOrg.orgId,
					userId: data.userId,
					userAccountId: data.userAccountId
				}
			}).then(({ data })=> {
				if(data?.code == 1){
					this.$message.success('已提交取消邀请');
					this.resPageData()
				}else{
					this.$message.error('取消失败');
				}
			})
		},
		/** 点击了邀请员工 */
		clickInviteEmployees() {
			this.InviteEmployeesPopup = true
		},
		/** 确认邀请员工 */
		InviteEmployeesOK() {
			this.InviteEmployeesPopup = false
		},
		/** 点击了冻结 */
		clickDJ(item) {
			let that = this
			if(item.status == 1){
				this.$confirm({
					title: `你确定要冻结：${item.userName}`,
					content: `电话：${item.mobile}`,
					okText: '冻结',
					cancelText: '取消',
					onOk: () => {
						console.log(111)
						stopUser({
							data:{
								appId: '',
								accountId: item.orgAccountId,
								userAccountId: item.userAccountId
							}
						}).then(({ data })=>{
							if(data?.code == 1){
								that.$message.success('已冻结');
								that.resPageData()
							}else{
								that.$message.success('冻结失败');
							}
						})
						
					},
				});
			}else{
				this.$confirm({
					title: `你确定要激活：${item.userName}`,
					content: `电话：${item.mobile}`,
					okText: '确定激活',
					cancelText: '取消',
					onOk: () => {
						activeUser({
							data:{
								appId: '',
								accountId: item.orgAccountId,
								userAccountId: item.userAccountId
							}
						}).then(({ data })=>{
							if(data?.code == 1){
								that.$message.success('已激活');
								that.resPageData()
							}else{
								that.$message.success('激活失败');
							}
						})
						
					},
				});
			}


			
		},
		/** 点击删除 */
		clickDEL(item) {
			let that = this
			this.$confirm({
				title: `你确定要删除：${item.userName}`,
				content: `电话：${item.mobile}`,
				okText: '删除',
				cancelText: '取消',
				onOk() {
					delUser({
						data:{
							appId: '',
							accountId: item.orgAccountId,
							userAccountId: item.userAccountId
						}
					}).then(({ data })=>{
						if(data?.code == 1){
							that.$message.success('已删除');
							that.resPageData()
						}else{
							that.$message.success('删除失败');
						}
					})
				},
				onCancel() {
					//
				},
			});
		},

		/** 点击编辑或者查看 */
		clickEDIT(item, type) {
			this.popupType = type
			this.viewOrEditPopup = true
		},

		/** 点击了邀请 */
		openInviteOk(){
			if(this.inv_phone && this.inv_name){
				let { selectOrg = {} } = this.$store.getters.getOrg || {}
				inviedUser({
					data:{
						userPhone: this.inv_phone,
						userName: this.inv_name,
						orgId: `${selectOrg.orgId}`,
						accountId: localStorage.getItem('orgAccountId')
					}
				}).then(({ data })=>{
					if(data?.code == 1 || data.data){
						this.$message.success('邀请成功！');
						this.resPageData()
					}else{
						this.$message.success('邀请失败！请重试');
					}
				}).finally(()=>{
					this.openInvitePopup = false
					this.phone = ''
					this.name = ''
				})
			}else{
				this.$message.error('姓名和电话不能为空');
			}
		}
	},
	watch:{
		getOrg(){
			this.resPageData()
		}
	}
}
</script>
<style lang="less" scoped>
.title {
	display: flex;
	padding-bottom: 16px;
}
.contract {
	width: 100%;
	padding: 16px;
}

.search-list {
	min-height: 400px;
	overflow: scroll;
}
.IE-list {
	margin-top: 20px;
}
.IE-checkbox {
	margin-right: 10px;
}
.invite-popup {
	input {
		margin-bottom: 10px;
	}
}
</style>