<template>
    <div>
        <ul class="head">
            <li v-for="(item, index) in headList"
                :key="index"
                :class="{active: selectIndex == item.index }"
                @click="changeTab(item)">{{ item.name }}</li>
            <div class="search" v-if="selectIndex == 2">
                <input type="text" v-model="searchText" placeholder="请输入模板名称进行搜索">
                <a-button type="primary" class="mr10" @click="clickSearch">搜索</a-button>
            </div>
        </ul>
        <router-view ref="TplContent"></router-view>
    </div>
    
</template>
<script>
export default {
    data() {
        return {
            headList: [{
                name: '模版管理',
                index: 2,
                link: '/template/manage'
            }, {
                name: '模版广场',
                index: 3,
                link: '/template/square'
            },{
                name: '我的收藏',
                index: 4,
                link: '/template/collection'
            }],
            selectIndex: 2,
            searchText: ''
        }
    },
    mounted(){
        let { i } = this.$route.query
        this.selectIndex = i || 2
    },
    methods:{
        clickSearch(){
            this.$refs.TplContent.resPageData(this.searchText)
        },
        changeTab(item){
            if(item.index != this.selectIndex){
                this.selectIndex = item.index
                this.searchText = ''
                this.$router.push({
                    path: item.link,
                    query:{
                        i: item.index
                    }
                })
            }
            
        }
    },


}
</script>
<style lang="less" scoped>
    .head {
        height: 48px;
        line-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(242 243 245);
        position: relative;
        background: rgba(255,255,255,0.5);
        backdrop-filter: saturate(180%) blur(20px);
        li {
            padding: 0px 20px;
        }
        .active {
            color: rgb(9 117 225);
        }
        .router-link-active {
            color: rgb(9 117 225);
        }
        .search {
            position: absolute;
            right: 20px;
            top: 9px;
            align-self: flex-end;
            display: flex;
            align-items: center;
            input {
                width: 200px;
            }
        }
    }
</style>