<template>
    <div class="contract">
        <div class="title">
            <a-button type="primary" class="mr10">发起出证</a-button>
        </div>
        <div>
            <a-table :columns="columns" :data-source="data" >
                <a slot="name" slot-scope="text">{{ text }}</a>
                <span slot="customTitle">申请人</span>

                <span slot="tags" slot-scope="tags">
                    <a-tag
                        v-for="tag in tags"
                        :key="tag"
                        :color="tag === '进行中' ? 'volcano' : tag == 2 ? 'geekblue' : 'green'"
                    >
                        {{ tag.toUpperCase() }}
                    </a-tag>
                </span>

                <span slot="action">
                    <a-dropdown>
                        <a-menu slot="overlay" >
                            <a-menu-item key="1">修改</a-menu-item>
                            <a-menu-item key="2">查看</a-menu-item>
                        </a-menu>
                        <a-button style="margin-left: 8px" type="primary" size="small"> 修改 <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </span>

            </a-table>
        </div>
        
    </div>
</template>
<script>
const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '证据类型',
    dataIndex: 'creatTime',
    key: 'creatTime',
  },
  {
    title: '申请时间',
    dataIndex: 'successTime',
    key: 'successTime',
  },
  {
    title: '申请出证业务名称',
    dataIndex: 'partake',
    key: 'partake',
  },
  {
    title: '状态',
    key: 'tags',
    dataIndex: 'tags',
    scopedSlots: { customRender: 'tags' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

const data = [
  // {
  //   key: '1',
  //   name: '深圳xxxx公司',
  //   creatTime: '2022-01-22',
  //   successTime: '2022-03-22',
  //   partake: '劳动合同',
  //   tags: ['进行中'],
  // },{
  //   key: '2',
  //   name: '张三（个人）',
  //   creatTime: '2022-01-22',
  //   successTime: '2022-03-22',
  //   partake: '房产物资转让合同',
  //   tags: ['完成'],
  // }
  ];
export default {
    data(){
        return {
            columns,
            data
        }
    }
}
</script>
<style lang="less" scoped>
.title {
     display: flex;
     padding-bottom: 16px;
}
.contract {
    width: 100%;
    padding: 16px;
   
}
</style>