<template>
    <div class="my-content">
        <!-- <ul class="left-nume">
            <li class="title">我的</li>
            <router-link tag="li" v-for="(item,index) in myList" :key="index" :to="item.link">
                <a-icon :type="item.ico" />{{ item.name }}
            </router-link>
            
        </ul> -->
        <router-view class="content"></router-view>
    </div>
</template>
<script>
export default {
    data(){
        return {
            myList:[{
                name: '基本信息',
                index: 0,
                ico: 'solution',
                link: '/my/information'
            },{
                name: '印章管理',
                index: 1,
                ico: 'safety',
                link: '/my/seal'
            },{
                name: '权益中心',
                index: 2,
                ico: 'gold',
                link: '/my/legalCenter'
            },{
                name: '员工管理',
                index: 3,
                ico: 'team',
                link: '/my/staff'
            },{
                name: '合同出证',
                index: 4,
                ico: 'file-protect',
                link: '/my/contract'
            },{
                name: '帮助',
                index: 5,
                ico: 'info-circle',
                link: '/my/help'
            }]
        }
    },
    methods:{
        goPage(item){
            console.log(item)
            if(this.$route.path != item.link){
                this.$router.push({
                    path: item.link
                })
            }
            
        }
    }
}
</script>
<style lang="less" scoped>
.my-content {
    display: flex;
    height: 100%;
    .left-nume {
        width: 200px;
        min-width: 200px;
        border-right: 1px solid rgb(230 230 230);
        .title {
            border-bottom: 1px solid rgb(230 230 230);
            padding: 20px 15px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 500;
        }
        li {
            padding: 10px 15px;
            font-size: 12px;
            i {
                margin-right: 5px;
            }
            &:hover {
                color: rgb(9 117 225);
            }
        }
        .router-link-active {
            color: rgb(9 117 225);
        }
    }
}
.content {
    padding: 20px;
}
</style>