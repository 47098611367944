<template>
    <router-view></router-view>
</template>

<script>
// api 


export default {
    data(){
        return {

        }
    },
    mounted(){
        console.log(1111)
        
    }
}
</script>