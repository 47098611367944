<template>
    <div class="login-page">
        <img class="logo" :src="LOGO" alt="" srcset="">
        <div class="box login" v-if="pageType == 1">
            <div class="content">
                <div class="head">
                    <p class="title">用户登录</p>
                    <span class="des">Log in your account</span>
                </div>
                
                <LgInput
                    :label="'手机号码'"
                    class="ipt-item"
                    type="phone"
                    :value="LG_phone"
                    :formart="phoneFormart"
                    maxlength="20"
                    @input="changeLG_Phone"
                ></LgInput>

                <LgInput
                    :label="'登录密码'"
                    class="ipt-item"
                    type="password"
                    :value="LG_pwd"
                    maxlength="12"
                    @input="changeLG_Pwd"
                ></LgInput>

                <p class="agreement">
                    <img :src="SELECT" class="select-ico" v-if="selectLoginXY" @click="selectLoginXY = !selectLoginXY">
                    <span class="no-select-ico" v-else  @click="selectLoginXY = !selectLoginXY"></span>
                    
                    我已阅读并同意<span @click="ServiceAgrePopup = true">《盾约服务协议》</span><span @click="PrivacyPolicyPopup = true">《隐私政策》</span>
                </p>
                <div class="login-btn" @click="clickLogin">登录</div>
                <div class="other">
                    <span class="change-login" @click="changeToRegister">用户注册</span>
                    <span class="forgot-password" @click="resetPassword">忘记密码?</span>
                </div>
            </div>
            
        </div>
        <div class="box register" v-else>
            <div class="content">
                <div class="head">
                    <p class="title">用户注册</p>
                    <span class="des">Sign up for an account</span>
                </div>
                
                <LgInput
                    :label="'手机号码'"
                    class="ipt-item"
                    type="phone"
                    maxlength="20"
                    :value="LG_phone"
                    :formart="phoneFormart"
                    :rightText="getCodeText"
                    @input="changeLG_Phone"
                >
                    <template v-slot:right>
                        <span :class="['right-text', { sendCode: isSendCode }]" @click="clickSendCode">{{ getCodeText }}</span>
                    </template>
                </LgInput>

                <LgInput
                    label="验证码"
                    class="ipt-item"
                    maxlength="8"
                    :value="REG_Code"
                    @input="changeREG_Code"
                ></LgInput>
                <LgInput
                    label="用户名"
                    class="ipt-item"
                    maxlength="20"
                    :value="REG_Name"
                    @blur="blueREG_Name"
                    @input="changeREG_Name"
                ></LgInput>
                <LgInput
                    label="登录密码"
                    class="ipt-item"
                    type="password"
                    maxlength="12"
                    :value="LG_pwd"
                    @blur="blueLG_pwd"
                    @input="changeLG_Pwd"
                ></LgInput>
                <LgInput
                    label="确认密码"
                    class="ipt-item"
                    type="password"
                    maxlength="12"
                    :value="REG_NewPwd"
                    @blur="blueREG_NewPwd"
                    @input="changeREG_NewPwd"
                ></LgInput>

                <p class="agreement">
                    <img :src="SELECT" class="select-ico" v-if="selectRXY" @click="selectRXY = !selectRXY">
                    <span class="no-select-ico" v-else  @click="selectRXY = !selectRXY"></span>
                    我已阅读并同意<span @click="ServiceAgrePopup = true">《盾约服务协议》</span><span @click="PrivacyPolicyPopup = true">《隐私政策》</span>
                </p>
                <div class="login-btn" @click="clickRegister">注册</div>
                <div class="other">
                    <span class="change-login" @click="changeToLogin">用户登录</span>
                </div>
            </div>
        </div>


        <a-modal
            :visible="ServiceAgrePopup"
            title="盾约电子签用户注册与使用协议"
            :footer="null"
            centered
            maskClosable
            width="50vw"
            @cancel="ServiceAgrePopup = false"
        >
            <ServiceAgre></ServiceAgre>
        </a-modal>


        <a-modal
            :visible="PrivacyPolicyPopup"
            title="盾约电子签隐私政策"
            :footer="null"
            centered
            maskClosable
            width="50vw"
            @cancel="PrivacyPolicyPopup = false"
        >
            <PrivacyPolicy></PrivacyPolicy>
        </a-modal>
    </div>
</template>
<script>

import LOGO from '@/assets/image/login/logo.png'
import SELECT from '@/assets/image/login/select.png'

// components
import  LgInput from '@/components/LgInput.vue'
import ServiceAgre from '@/components/ServiceAgre.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'


// api
import { getRegistSmsCode, userRegist, userLogin, checkAccountStatus } from '@/api'

export default {
    data(){
        return {
            LOGO,
            SELECT,
            pageType: 1, // 1 登陆 2 注册


            LG_phone: '',
            LG_pwd: '',

            // 注册
            REG_Code: '',
            REG_Name: '',
            REG_NewPwd: '',



            selectLoginXY: false,  // 选择登陆协议
            selectRXY: false,   //  选择注册协议

            getCodeText: '获取验证码',  // 获取验证码的文字
            isSendCode: false,          // 是否发送了验证码


            ServiceAgrePopup: false,
            PrivacyPolicyPopup: false
        }
    },
    computed:{
        /** 两次密码是否相同 */
        REG_PwdIdentical(){
            if(this.REG_NewPwd && this.REG_NewPwd == this.LG_pwd){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        /** 点击获取验证码 */
        clickSendCode() {
            if(!this.isSendCode){
                if(!this.LG_phone){
                    this.$message.error('请输入正确的手机号');
                    return
                }
                this.isSendCode = true
                let that = this
                getRegistSmsCode({
                    params:{
                        mobile: this.LG_phone
                    }
                }).then(({ data })=>{
                    if(data.code == 1){
                        this.$message.success('验证码已发送');
                        let time = 60
                        this.getCodeText = `60秒后重新获取`
                        let T = setInterval(()=> {
                            time = --time
                            that.getCodeText = `${time}秒后重新获取`
                            if( time <= 0 ){
                                clearInterval(T)
                                that.isSendCode = false
                                that.getCodeText = '获取验证码'
                            }
                        },1000)
                    }else{
                        this.$message.error('验证码获取失败');
                        this.isSendCode = false
                    }
                })
            }
        },

        /** 手机号交验 */
        phoneFormart({ value }){
            return value.replace(/[^0-9]/ig, "")
        },
        /** 密码校验 */
        blueLG_pwd(){
            if(this.LG_pwd && this.LG_pwd.length < 8){
                this.$message.error('密码至少为8-12位，字母+数字+特殊符号');
            }
        },
        /** 两次密码校验 */
        blueREG_NewPwd(){
            if(this.LG_pwd != this.REG_NewPwd){
                this.$message.error('两次密码不一致');
            }
        },

        /** 用户名校验 */
        blueREG_Name(){
            if(this.REG_Name && this.REG_Name.length < 2){
                this.$message.error('用户名至少为两2个字符');
            }
        },

        /** 点击了登陆 */
        clickLogin(){
            if(!this.LG_phone || !this.LG_pwd){
                this.$message.error('请填写手机号或密码');
                return
            }
            if(!this.selectLoginXY){
                this.$message.error('请勾选页面协议');
                return
            }
            checkAccountStatus({
              data: {
                phoneNumber: this.LG_phone
              }
            }).then(({ data })=>{
                if (data?.code === 1){
                  this.$message.error('您的账号已注销,如有疑问可联系客服 18814372831', 5);
                } else{
                    this.login();
                }
            })
        },
        login() {
          userLogin({
            data:{
              mobile: this.LG_phone,
              passwd: this.LG_pwd
            }
          }).then(({ data })=>{
            if(data.code == 1){
              this.$message.success('登录成功');
              let userData = data.data || {}
              localStorage.setItem('userId', userData.userId || '')
              localStorage.setItem('accountId', userData.accountId || '')
              localStorage.setItem('YDuserInfo',JSON.stringify(userData))
              this.$store.dispatch("setState", {
                userInfo: userData || {},
              });
              this.$router.push({
                name: 'home'
              })
            }else{
              this.$message.error(data.msg || '登录失败！请重试');
            }
          })
        },
        /** 点击了注册 */
        clickRegister(){
            if(!this.LG_phone || !this.REG_Code || !this.REG_Name || !this.LG_pwd || !this.REG_NewPwd){
                this.$message.error('请填写完整的页面信息');
                return
            }
            if(this.REG_Name && this.REG_Name.length < 2){
                this.$message.error('用户名至少为两2个字符');
                return
            }
            
            if(this.LG_pwd && this.LG_pwd.length < 8){
                this.$message.error('密码至少为8-12位，字母+数字+特殊符号');
                return
            }
            if(!this.REG_PwdIdentical){
                this.$message.error('两次输入的密码不一致');
                return;
            }
            if(!this.selectRXY){
                this.$message.error('请勾选页面协议');
                return
            }
            if(this.LG_phone && this.REG_Code && this.REG_Name && this.REG_Code && this.LG_pwd){
                userRegist({
                    data:{
                        mobile: this.LG_phone,
                        displayName: this.REG_Name,
                        smsCode: this.REG_Code,
                        passwd: this.LG_pwd
                    }
                }).then(({ data })=>{
                    if(data.code ==1){
                        this.$message.success('注册成功');
                        let that = this
                        setTimeout(()=>{
                            that.pageType = 1
                        },2000)
                    }else{
                        this.$message.error(data.msg || '注册失败！请重试');
                    }
                })
            }else{
                this.$message.error('请填写页面完整的信息');
            }
            
        },

        /** 切换到注册 */
        changeToRegister(){
            this.resetPageData()
            this.pageType = 2
        },
        /** 切换到登陆 */
        changeToLogin(){
            this.resetPageData()
            this.pageType = 1
        },
        /** 清空页面填入的所有数据 */
        resetPageData(){
            this.LG_phone = ''
            this.LG_pwd = ''
            this.REG_Code = ''
            this.REG_Name = ''
            this.REG_NewPwd = ''
        },

        /** 跳转忘记密码 */
        resetPassword(){
            this.$router.push({
                name: 'resetPassword'
            })
        },




        /** 登陆手机号 */
        changeLG_Phone({ value }){
            this.LG_phone = value
        },
        changeLG_Pwd({ value }){
            this.LG_pwd = value
        },
        changeREG_Code({ value }){
            this.REG_Code = value
        },
        changeREG_Name({ value }){
            this.REG_Name = value
        },
        changeREG_NewPwd({ value }){
            this.REG_NewPwd = value
        }

    },
    components: {
        LgInput,
        ServiceAgre,
        PrivacyPolicy
    }
}
</script>
<style lang="less" scoped>
.login-page {
    background-image: url(../assets/image/login/login_bg.png);
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    .logo {
        position: absolute;
        width: 60px;
        left: 100px;
        top: 110px;
        height: auto;
    }
    .box {
        position: absolute;
        top: 20vh;
        right: 10%;
        width: 580px;
        height: 700px;
        border-radius: 20px;
        background: #ffffff;
        color: #000000;
        box-shadow: 0px 0px 10px rgba(50, 115, 120, .15);
        .content {
            position: relative;
            z-index: 100;
            padding: 0px 70px;
            .head {
                padding-left: 20px;
                padding-bottom: 80px;
                padding-top: 90px;
            }
            .title {
                color: #004043;
                font-size: 22px;
                
                font-weight: 500;
            }
            .des {
                font-size: 16px;
                color: #B5C4C5;
                font-weight: 400;
            }
            .ipt-item {
                margin-bottom: 20px;
            }
            .select-ico {
                width: 36px;
            }
            .no-select-ico {
                border: 1px solid #00908F;
                width: 20px;
                height: 20px;
                border-radius: 4px;
                margin: 8px;
            }
        }
    }
}
.agreement {
    font-size: 16px;
    color: #004043;
    margin-bottom: 80px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    span {
        color: #007FEF;
        &:hover{
            text-decoration: underline;
        }
    }
    .select {
        width: 40px;
    }
}
.login-btn {
    background: #00908F;
    color: #fff;
    font-size: 20px;
    padding: 20px 0px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 144, 143, 0.50);
}
.other {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    font-size: 16px;
    .change-login {
        color: #007FEF;
        &:hover{
            text-decoration: underline;
        }
    }
    .forgot-password {
        color: #B5C4C5;
    }
}


// 注册
.register {
    .content {
        .head {
            padding-top: 60px !important;
            padding-bottom: 20px !important;
        }
        .agreement {
            margin-bottom: 20px;
        }
        .other {
            justify-content: center;
        }
        .sendCode {
            color: #c4c4c4;
        }
    } 
}
</style>