<template>
    <ul class="step">
        <li v-for="(item,index) in stepList" :key="index" @click="changeStep(item)">
            <template v-if="step == 1">
                <img v-if="item.index ==1" :src="SEL1">
                <img v-else :src="NO2">
            </template>

            <template v-if="step == 2">
                <img v-if="item.index == 1" :src="SEL">
                <img v-if="item.index == 2" :src="SEL2">
                <img v-if="item.index == 3" :src="NO3">
            </template>

            <template v-if="step == 3">
                <img v-if="item.index < 3" :src="SEL">
                <img v-else :src="SEL3">
            </template>
            
            <span>{{ item.text }}</span>
            <img :src="ARROW" class="arrow" v-if="item.index < 3"/>
        </li>
    </ul>
</template>
<script>

import SEL1 from '@/assets/image/login/1sel.png'
import SEL2 from '@/assets/image/login/2sel.png'
import SEL3 from '@/assets/image/login/3sel.png'
import NO3 from '@/assets/image/login/3no.png'
import NO2 from '@/assets/image/login/2no.png'
import SEL from '@/assets/image/login/sel.png'
import ARROW from '@/assets/image/login/arrow.png'


export default {
    data(){
        return {
            SEL1,
            SEL2,
            SEL3,
            NO3,
            NO2,
            SEL,
            ARROW,
            stepList:[{
                index: 1,
                text: '账号校验'
            },{
                index: 2,
                text: '密码重置'
            },{
                index: 3,
                text: '完成设置'
            }]
        }
    },
    props: {
        step: {
            type: Number,
            default: 1
        }
    },
    methods:{
        changeStep(item){
            this.$emit('changeStep',{
                ...item
            })
        }
    }
}
</script>
<style lang="less" scoped>
.step {
    display: flex;
    padding: 0px 25%;
    justify-content: space-between;
    li {
        display: flex;
        align-items: center;
        img {
            width: 26px;
            margin-right: 10px;
        }
        .arrow {
            width: 10px;
            margin-left: 40px;
        }
        span {
            color: #004043;
            font-size: 22px;
        }
    }
}
</style>