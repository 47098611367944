<template>
    <div class="right-seal">
        <div class="left-menu">
            <template v-if="isShowLeft">
                <section>
                    <p class="kj-tit">选择控件</p>
                    <p class="des mb10">注：点击即可在当前页面添加控件</p>
                    <ul class="sign-box">
                        <li>
                            <p @click="addSign('签署区', 'sign', 'dq')">
                                <img src="@/assets/image/QS/DF.png"/>
                                <span>单方签署</span>
                            </p>
                            <p @click="addSign('签署区', 'sign', 'sq')">
                                <img src="@/assets/image/QS/SF.png"/>
                                <span>双方签署</span>
                            </p>
                        </li>
                        <li>
                            <p @click="addSign('输入框', 'input', 'danh')">
                                <img src="@/assets/image/QS/DAH.png"/>
                                <span>单行输入</span>
                            </p>
                            <p @click="addSign('输入框', 'textarea', 'duoh')">
                                <img src="@/assets/image/QS/DUH.png"/>
                                <span>多行输入</span>
                            </p>
                        </li>
                        <li>
                            <p @click="addSign('输入框', 'input', 'name', 'jf')">
                                <img src="@/assets/image/QS/JF-XM.png"/>
                                <span>姓名/代理人<br>(甲方)</span>
                            </p>
                            <p @click="addSign('输入框', 'input', 'name', 'yf')">
                                <img src="@/assets/image/QS/YF-XM.png"/>
                                <span>姓名/代理人<br>(已方)</span>
                            </p>
                        </li>
                        <li>
                            <p @click="addSign('输入框', 'input', 'idCard', 'jf')">
                                <img src="@/assets/image/QS/JF-SFZ.png"/>
                                <span>身份证<br>(甲方)</span>
                            </p>
                            <p @click="addSign('输入框', 'input', 'idCard', 'yf')">
                                <img src="@/assets/image/QS/YF-SFZ.png"/>
                                <span>身份证<br>(已方)</span>
                            </p>
                        </li>
                        <li>
                            <p @click="addSign('输入框', 'input', 'phone', 'jf')">
                                <img src="@/assets/image/QS/JF-DH.png"/>
                                <span>联系电话<br>(甲方)</span>
                            </p>
                            <p @click="addSign('输入框', 'input', 'phone', 'yf')">
                                <img src="@/assets/image/QS/YF-DH.png"/>
                                <span>联系电话<br>(已方)</span>
                            </p>
                        </li>
                        <li>
                            <p @click="addSign('输入框', 'input', 'org', 'jf')">
                                <img src="@/assets/image/QS/JF-JG.png"/>
                                <span>机构名称<br>(甲方)</span>
                            </p>
                            <p @click="addSign('输入框', 'input', 'org', 'yf')">
                                <img src="@/assets/image/QS/YF-JG.png"/>
                                <span>机构名称<br>(已方)</span>
                            </p>
                        </li>
                        <li>
                            <p @click="addSign('输入框', 'input', 'code', 'jf')">
                                <img src="@/assets/image/QS/JF-DM.png"/>
                                <span>机构信用代码<br>(甲方)</span>
                            </p>
                            <p @click="addSign('输入框', 'input', 'code', 'yf')">
                                <img src="@/assets/image/QS/YF-DM.png"/>
                                <span>机构信用代码<br>(已方)</span>
                            </p>
                        </li>
                    </ul>



                    <div class="info">
                        <p class="tit">模块信息</p>
                        <a-form-model-item label="名称：">
                            <a-input v-model="selectSignatoryName" @change="changeSignatoryName" />
                        </a-form-model-item>
                        
                        <!-- <a-form-model-item label="显示签署日期">
                            <a-switch />
                        </a-form-model-item> -->
                        <a-form-model-item v-if="selectSignatory.type">
                            <a-button type="danger" @click="deleteBox">删除</a-button>
                        </a-form-model-item>
                        
                    </div>
                    <p class="theme-btn success" @click="addConTpl">确认提交</p>
                </section>
            </template>
            <template v-if="pageType == 'view'">
                <a-button type="primary" @click="goBack">返回</a-button>
                
                <p class="tpl-name">{{ titleText }}名称：{{ viewData.templateName }}</p>
                <p class="des">{{titleText}}描述：{{ viewData.templateDesc || '--' }}</p>
                <p class="des">发起人：{{ viewData.createUserName }}</p>
                <p class="des">发起时间：{{ viewData.createTm }}</p>
                <p class="des">截止时间：{{ viewData.endSignTm }}</p>
                <p class="des" style="margin-top:80px;">签署方({{signed}})</p>
                <div class="signed" v-for="(item,index) in signedmes" :key="index" :style="item.powerFlag == true ? 'background:#e4f7f7' : 'background:#edf7e4'">
                    <p class="des">{{item.powerFlag == true ? '甲方' : '乙方'}}</p>
                    <p class="des">签署类型：{{item.participantType == 1 ? '个人' : '机构'}}</p>
                    <p class="des">签署方：{{item.participantName}}</p>
                    <p class="des">签署方手机号：{{item.participantPhone}}</p>
                </div>
                <template v-if="pageFrom != 'self'">
                    <p>{{ titleText }}类别：{{ viewData.categoryName || '暂无分类' }}</p>
                    <p class="favorite" @click="addCollect(viewData)">收藏：
                        <span :class="{ active: viewData.favoriteId }" ><a-icon type="heart" :theme="viewData.favoriteId ? 'filled' : 'outlined'"/></span>
                    </p>
                </template>
            </template>
        </div>

        <div class="pdf-content show-pdf">
            <div :class="['pdf', pdfClass ? 'no_padding' : '']" v-if="pageFrom == 'self'">
                <pdf
                    v-for="i in numPages"
                    :key="i"
                    :src="src"
                    :page="i"
                    class="page-pdf show-pdf"
                ></pdf>
                <div id="pdfh5"></div>
            </div>
            <template v-else>
                <div class="pdf-dom" ref="PDFDOM">
                    <pdf
                        :src="pdfSrc"
                        :page="pageNum"
                        @progress="loadedRatio = $event"
                        @num-pages="pageTotalNum = $event"
                        height="100%"
                        class="show-pdf"
                    ></pdf>
                    <div v-for="(item, index) in signList" :key="index">
                        <div
                            v-if="item.type == 'input'"
                            v-show="pageNum == item.pageIndex && item.isShow"
                            @mousedown="mousedown($event, item, index)"
                            @mousemove="mousemove($event, item)"
                            @mouseup="mouseup($event, item)"
                            @mouseover="mouseup($event, item)"
                        >
                            <textarea disabled :style="{left:`${item.l}px`,top:`${item.t}px`,width:`${item.width}px`}"  :class="['seal-box',item.type, item.key]" v-model="item.name"></textarea>
                        </div>
                        <div
                            v-else-if="item.type == 'textarea'"
                            v-show="pageNum == item.pageIndex && item.isShow"
                            @mousedown="mousedown($event, item, index)"
                            @mousemove="mousemove($event, item)"
                            @mouseup="mouseup($event, item)"
                            @mouseover="mouseup($event, item)"
                        >
                            <textarea disabled :style="{left:`${item.l}px`,top:`${item.t}px`,width:`${item.width}px`}" :class="['seal-box',item.type, item.key]" v-model="item.name"></textarea>
                        </div>

                        <div
                            v-else
                            :class="['seal-box', item.type, item.key]"
                            v-show="pageNum == item.pageIndex && item.isShow"
                            :style="{left:`${item.l}px`,top:`${item.t}px`,width:`${item.width}px`,height:`${item.height}px`}"
                            @mousedown="mousedown($event, item, index)"
                            @mousemove="mousemove($event, item)"
                            @mouseup="mouseup($event, item)"
                            @mouseover="mouseup($event, item)"
                        >
                        {{ item.name }}
                        <p class="time">____年__月__日</p>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <a-button-group>
                        <a-button @click="prePage">
                            <a-icon type="left" />上一页
                        </a-button>
                        <a-button>{{ pageNum }}/{{ pageTotalNum }}</a-button>
                        <a-button @click="nextPage">
                            下一页
                            <a-icon type="right" />
                        </a-button>
                    </a-button-group>
                </div>
            </template>
        </div>
        <!-- <div class="right-box">
        </div> -->
    </div>
</template>
<script>
import pdf from '@/components/pdfVue/vuePdfNoSss.vue'
// import pdf from 'vue-pdf'
// import pdf from 'vue-pdf-signature'
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";

import { previewFile, addContractTemplate, updateContractTemplate, queryContractTemplateDetail, queryContractById, delFavoriteTemplate, favoriteTemplate } from '@/api'


export default {
    data() {
        return {

            pdfh5: null,
            titleText: '模版',


            pageType: '',  // 页面处于新增｜ 查看 ｜ 编辑
            pdfSrc: '',
            pageNum: 1,
            pageTotalNum: 1, // 总页数
            loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了

            selectSignatoryName: '未选择',

            signatory: [], //签署框
            ZJ_name:{
                jf:{},
                yf:{}
            }, // 组件-代理人
            ZJ_phone:{
                jf:{},
                yf:{}
            }, // 组件-电话
            ZJ_orgName:{
                jf:{},
                yf:{}
            }, // 组件-机构名称
            ZJ_orgCode:{
                jf:{},
                yf:{}
            },


            inputBox:[], // 输入框
            selectSignatory: {},
            selectIndex: 0,

            RES_signList: [],   // 接口请求的组件的列表

            isShowLeft: true, // 是否显示左侧
            viewId: '',  // 查看时的ID
            templateName: '', // 编辑时候的合同名称

            viewData: {}, // 查看模版返回的数据集合
            pageFrom: '', // 页面来源
            signed:"",
            signedmes:[],
            fileId: '',
            annexId: '',
            initiate:"",
            dhIndex: 1, // 但行的key 增加
           
        }
    },

    computed:{
        signList(){
            return [].concat(this.signatory, this.inputBox)
        }
    },
    mounted(){
        let { fileId, annexId, type, id, name, from  } = this.$route.query
        // let { fileUrl , type } = this.$route.params
        console.log(this.$route.params,'query',this.$route.query)
        this.annexId = annexId
        if(fileId){
            this.viewFile(fileId)
        }
        this.pageType = type || ''

        this.pageFrom = from

        if(type == 'view'){
            this.isShowLeft = false
            this.viewId = id
            this.viewPageInfo()
        }else if(type == 'edit'){
            this.templateName = name
            this.viewId = id
            this.viewPageInfo()
        }
        console.log(fileId, 'fileId',annexId,'annexId',this.pageType,'this.pageType',this.pageFrom,'this.pageFrom')
    },
    methods: {
        // 预览文件
        viewFile(fileId){
            this.fileId = fileId
            previewFile({
                data:{
                    id: fileId * 1
                }
            }).then(({ data })=>{
                let pdfUrl = data.data
                this.pdfSrc = pdfUrl

                setTimeout(() => {
                    this.renderSignList()
                }, 1000);
            })
        },

        // PDF 渲染完毕
        pdfLoaed(){
            console.log('OK')
        },

        // r如果是查看页面信息
        viewPageInfo(){
            if(this.pageFrom == 'self'){
                this.titleText = '合同'
                queryContractById({
                    data:{
                        id: this.viewId * 1,
                        accountId: localStorage.getItem('orgAccountId')
                    }
                }).then(({ data })=>{


                    let getData = data?.data

                    this.viewData = getData
                    this.signed = this.viewData.contractParticipantList.length
                    this.signedmes = this.viewData.contractParticipantList
                    console.log(this.signedmes,'test')
                    // for(let i in this.signedmes){
                    //     if(this.signedmes[i].powerFlag == true){
                    //         this.signedmes[i].participantName = this.initiate
                    //         console.log('test', this.initiate,'ooo',this.signedmes[i],'q',this.signedmes[i].participantName)
                    //     }
                    // }
                    // this.viewData = {
                    //     templateName: getData.contractName
                    // }
                    // let signList = getData.componentDtoList || []


                    // console.log(getData)


                    // this.viewFile(getData.signFileUrl)
                    // this.fileId = getData.signFileUrl
                    // this.pdfSrc = getData.signFileUrl

                    this.pdfh5 = new Pdfh5("#pdfh5", {
                        pdfurl: getData?.signFileUrl
                    });
                    //监听完成事件
                    this.pdfh5.on("complete", function (status, msg, time) {
                        console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
                    })


                    // this.RES_signList = signList

                    // setTimeout(() => {
                    //     this.renderSignList()
                    // }, 1000);



                

                    console.log(data)

                })
            }else{
                queryContractTemplateDetail({
                    data: {
                        id: this.viewId * 1,
                        accountId: localStorage.getItem('orgAccountId')
                    }
                }).then(({ data })=>{
                    let getData = data?.data

                    this.viewData = getData
                    let signList = getData.componentDtoList || []

                    let fileList = getData?.contractTempFileList || []

                    let fileId = ''
                    fileList.forEach(item=> {
                        if(item.fileType === true){
                            fileId = item.id
                        }
                    })


                    this.viewFile(fileId)
                    this.fileId = fileId


                    this.RES_signList = signList


                

                    console.log(data,'data')
                })
            }
            
        },

        // 渲染页面上的组件
        renderSignList(){
            let signList = this.RES_signList

            let PDFDOM = this.$refs.PDFDOM
            let Canvas = PDFDOM.querySelector('span')
            let PDFDOM_W = Canvas.clientWidth
            let PDFDOM_H = Canvas.scrollHeight //.querySelector('span')

                if(!PDFDOM_W){
                    setTimeout(()=>{
                        this.renderSignList()
                    },1000)
                    return 
                }
                // let fileId = getData?.contractTempFileList[0]?.id
                if(signList.length > 0){
                    let signatoryList = []
                    let inputBoxList = []
                    signList.forEach(item=>{
                        let left = item.posX 
                        let top = item.pageH - item.posY
                        let width = item.width
                        let height = item.height



                        if(item.pageW != PDFDOM_W){ // 页面比例缩放
                            left = (PDFDOM_W / item.pageW) * left
                            top = (PDFDOM_H / item.pageH) * top

                            width = (PDFDOM_W / item.pageW) * width
                            height = (PDFDOM_W / item.pageW) * height

                        }

                        if([11,12,13].includes(item.componetType)){
                            signatoryList.push({
                                key: item.componetKey,
                                name: item.label,
                                componetType: item.componetType,
                                type: 'sign', //签署区 || 骑缝章
                                x: 0,
                                y: 0,
                                l: left,
                                t: top,
                                pageW: item.pageW,
                                pageH: item.pageH,
                                pageIndex: item.posPage,
                                isDown: false,
                                isShow: true,
                                height: height,
                                width: width,
                            })
                        }else{
                            inputBoxList.push({
                                key: item.componetKey,
                                name: item.label,
                                componetType: item.componetType,
                                type: item.componetType == 2 ? 'textarea' : 'input', //签署区 || 骑缝章
                                x: 0,
                                y: 0,
                                l: left,
                                t: top,
                                height: height,
                                width: width,
                                pageW: item.pageW,
                                pageH: item.pageH,
                                pageIndex: item.posPage,
                                isDown: false,
                                isShow: true,
                            })
                        }
                    })

                    this.signatory = signatoryList
                    this.inputBox = inputBoxList
                }
        },
        // 上一页
        prePage() {
            // console.log(this.$refs.PDFDOM.clientWidth) //clientHeight
            let page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },

        // 下一页
        nextPage() {
            let page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        },
        addSign(item, type, name, role) {
            if(name == 'sq' || name == 'dq'){
                this.signatory = [] // 初始化
                if(name == 'sq'){
                    this.signatory.push({
                        componetType: 12,
                        name: '乙方签署区域',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 50,
                        t: 300,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'yf_qs',
                    })
                    this.signatory.push({
                        componetType: 11,
                        name: '甲方签署区',
                        type: type + ' two', //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 300,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'jf_qs',
                    })
                }else{
                    this.signatory.push({
                        componetType: 13,
                        name: '单方签署区',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 300,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'df_qs',
                    })
                }
                
            }else if(name == 'name'){
                if(role == 'jf'){
                    this.inputBox.push({
                        componetType: 3,
                        name: '姓名/代理人(甲方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'jf_name',
                    })
                }else{
                    this.inputBox.push({
                        componetType: 7,
                        name: '姓名/代理人(乙方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'yf_name',
                    })
                }
            }else if(name == 'phone'){
                if(role == 'jf'){
                    this.inputBox.push({
                        componetType: 4,
                        name: '联系电话(甲方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'jf_phone',
                    })
                }else{
                    this.inputBox.push({
                        componetType: 8,
                        name: '联系电话(乙方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'yf_phone',
                    })
                }
            }else if(name == 'org'){
                if(role == 'jf'){
                    this.inputBox.push({
                        componetType: 5,
                        name: '机构名称(甲方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'jf_jg_name',
                    })
                }else{
                    this.inputBox.push({
                        componetType: 9,
                        name: '机构名称(乙方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'yf_jg_name',
                    })
                }
            }else if(name == 'code'){
                if(role == 'jf'){
                    this.inputBox.push({
                        componetType: 6,
                        name: '机构信用代码(甲方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'jf_code',
                    })
                }else{
                    this.inputBox.push({
                        componetType: 10,
                        name: '机构信用代码(乙方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'yf_code',
                    })
                }
            }else if(name == 'idCard'){
                if(role == 'jf'){
                    this.inputBox.push({
                        componetType: 15,
                        name: '身份证号(甲方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'jf_card',
                    })
                }else{
                    this.inputBox.push({
                        componetType: 14,
                        name: '身份证号(乙方)',
                        type, //签署区 || 骑缝章
                        x: 0,
                        y: 0,
                        l: 100,
                        t: 400,
                        pageW: 0,
                        pageH: 0,
                        pageIndex: this.pageNum,
                        isDown: false,
                        isShow: true,
                        key: 'yf_card',
                    })
                }
            }else{
                this.dhIndex += 1
                this.inputBox.push({
                    componetType: name == 'danh' ? 1 : 2,
                    name: item + (this.signatory.length + 1),
                    type, //签署区 || 骑缝章
                    x: 0,
                    y: 0,
                    l: 100,
                    t: 400,
                    pageW: 0,
                    pageH: 0,
                    pageIndex: this.pageNum,
                    isDown: false,
                    isShow: true,
                    key: 'dh' + this.dhIndex,
                })
            }
            
        },
        // 按下
        mousedown(e, item, index) {
            this.selectSignatory = item
            this.selectSignatoryName = item.name
            this.selectIndex = index
            let { target } = e
            // console.log(e,target.offsetTop)
            // //获取x坐标和y坐标
            item.x = e.clientX - target.offsetLeft;
            item.y = e.clientY - target.offsetTop;

            // //获取左部和顶部的偏移量
            item.l = target.offsetLeft;
            item.t = target.offsetTop;
            // //开关打开
            item.isDown = true;
        },
        // 移动
        mousemove(e, item) {
            if(this.pageType == 'view'){ // 查看的时候不能拖动
                return 
            }
            // let { target } = e
            if (item.isDown == false) {
                return;
            }
            // // //获取x和y

            var nx = e.clientX;
            var ny = e.clientY;
            // // //计算移动后的左偏移量和顶部的偏移量
            var nl = nx - item.x;
            var nt = ny - item.y;


            if (nl <= 0) {
                nl = 0
            }
            if (nt <= 0) {
                nt = 0
            }

            // target.style.left = nl + 'px';
            // target.style.top = nt + 'px';
            item.l = nl;
            item.t = nt;
        },
        //弹起
        mouseup(e, item) {
            // let { target } = e
            // // //获取左部和顶部的偏移量
            // item.l = target.offsetLeft;
            // item.t = target.offsetTop;
            item.isDown = false;
        },
        changeSignatoryName() {
            this.signList.forEach((item, index) => {
                if (index == this.selectIndex) {
                    item.name = this.selectSignatoryName
                }
            })
        },
        // 删除
        deleteBox(){
            this.signList.forEach((item, index) => {
                if (index == this.selectIndex) {
                    item.isShow = false
                }
            })
        },

        //查看的时候显示返回
        goBack(){
            this.$router.go(-1)
        },

        // 添加收藏
        async addCollect(item){
            if(item.favoriteId) {
                let that = this
                this.$confirm({
                    title: `你确定要取消收藏：${item.templateName}`,
                    okText: '确定',
                    cancelText: '取消',
                    onOk() {
                        delFavoriteTemplate({
                            data:{
                                id: item.id,
                                accountId: localStorage.getItem('orgAccountId')
                            }
                        }).then(({ data })=>{
                            console.log(11111,that)
                            if(data?.code == 1){
                                that.$message.success('取消收藏成功');
                                // that.resPageData()
                                
                            }else{
                                that.$message.error('取消收藏失败');
                            }
                            that.viewPageInfo()
                        })
                    }
                })
                
            }else{
                // let { selectOrg = {} } = this.$store.getters.getOrg || {}
                await favoriteTemplate({
                    data:{
                        id: item.id,
                        accountId: localStorage.getItem('orgAccountId')
                    }
                }).then(({ data })=>{
                    if(data?.code == 1){
                        this.$message.success('已收藏');
                    }else{
                        this.$message.error('收藏失败');
                    }
                })
                this.viewPageInfo()
            }
           

            
            // item.collect = !item.collect
        },

        //提交数据
        addConTpl(){
            let signTplInfo = JSON.parse(localStorage.getItem('signTplInfo') || '{}')

            let PDFDOM = this.$refs.PDFDOM
            let Canvas = PDFDOM.querySelector('span')
            let PDFDOM_W = Canvas.clientWidth
            let PDFDOM_H = Canvas.scrollHeight //.querySelector('span')

            console.log({
                PDFDOM_W,
                PDFDOM_H
            })



            // 获取签署框列表
            let signatoryList = []
            
            this.signatory.forEach(item=>{
                let W = document.querySelector(`.${item.key}`)?.offsetWidth || 120
                let H = document.querySelector(`.${item.key}`)?.offsetHeight || 120
                signatoryList.push({
                    componetKey: item.key, //控件key
                    componetType: item.componetType,
                    //componetType: '11', //组件类型:1-单行文本，2-数字，3-日期，8-多行文本，11-图片
                    label: item.name,
                    height: H,
                    width: W,
                    posPage: item.pageIndex,
                    posX: item.l,
                    posY: PDFDOM_H - item.t,
                    required: 1,
                    pageW: PDFDOM_W,
                    pageH: PDFDOM_H
                })
            })

            // 获取输入框的列表
            let inputBoxList = []
            this.inputBox.forEach(item=>{
                if(item.isShow){
                    let W = document.querySelector(`.${item.key}`)?.offsetWidth || 100
                    let H = document.querySelector(`.${item.key}`)?.offsetHeight || 24
                    console.log(W,'w')
                    // let W = 300
                    // let H = 124
                    inputBoxList.push({
                        componetType: item.componetType,
                        componetKey: item.key, //控件key
                        //componetType: item.type == 'input' ? '1' : '8', //组件类型:1-单行文本，2-数字，3-日期，8-多行文本，11-图片
                        label: item.name,
                        height: H,
                        width: W,
                        posPage: item.pageIndex,
                        posX: item.l,
                        posY:PDFDOM_H - item.t,
                        required: 1,
                        pageW: PDFDOM_W,
                        pageH: PDFDOM_H
                    })
                }
            })

            let fileIdList = [{
                    id: Number(signTplInfo.fileId),
                }]
            if(this.annexId){
                let annexIdList = this.annexId.split(',')
                annexIdList.forEach(item => {
                    fileIdList.push({
                        id: Number(item) || ''
                    })
                })
            }
            
            if(this.pageType == 'edit'){
                let editEesData = {
                    accountId: localStorage.getItem('orgAccountId'),
                    isTemp: 1,
                    templateName: this.templateName,
                    contractTempFileList: fileIdList,
                    componentDtoList: [].concat(signatoryList, inputBoxList),
                    id: this.viewId * 1,
                    templateDesc: signTplInfo.introduction || ''
                }
                updateContractTemplate({
                    data: editEesData
                }).then(({ data })=>{
                    if(data.code == 1){
                        this.$message.success('修改成功');
                        this.$router.push({
                            path: '/template/manage'
                        })
                    }else{
                        this.$message.success('修改失败');
                    }
                })
            }else{

                
                let resData = {
                    accountId: localStorage.getItem('orgAccountId'),
                    templateName: signTplInfo.signTitle,
                    isTemp: 1,
                    contractTempFileList: fileIdList,
                    componentDtoList: [].concat(signatoryList, inputBoxList),
                    templateDesc: signTplInfo.introduction || ''
                }
                addContractTemplate({
                    data: resData
                }).then(({ data })=>{
                    if(data.code == 1){
                        this.$message.success('添加成功');
                        this.$router.push({
                            path: '/template/manage'
                        })
                    }else{
                        this.$message.success('添加失败');
                    }
                })
            }

            
        }

    },
    components: {
        pdf
    }
}
</script>
<style lang="less" scoped>
.right-seal {
    display: flex;
    justify-content: flex-start;
    .left-menu,
    .right-box {
        width: 20%;
        min-width: 200px;
        padding: 16px;
        box-sizing: border-box;
    }
    .signed{
        width: 100%;
        height: auto;
        background: #0975e1;
        border-radius: 5px;
        padding: 10px 8px;
        margin-bottom: 20px;
        .des{
            color: #000 !important;
        }
    }
}

.pdf-content {
    position: relative;
    width: 80%;
    display: flex;
    // justify-content: center;

    .pdf-dom {
        // padding: 0px 20px 60px 20px;
        // max-width: 50vw;
        width: 100%;
        max-width: 1000px;
        min-height: calc(100vh - 56px);
        max-height: calc(100vh - 56px);
        overflow: scroll;
        // border: 1px solid #888;
        position: relative;
        background: #fff;
        text-align: center;
        box-shadow: 0 0 5px #c4c4c4;
    }
    .footer {
        // margin-left: calc(50% - 25vw);
        // max-width: 50vw;
        text-align: center;
        width: 100%;
        padding: 10px 0px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 999;
    }
    .seal-box {
        min-width: 30px;
        max-width: 500px;
        height: 120px;
        background: rgba(200, 200, 255, 0.5);
        border: 1px dashed rgba(255, 0, 0, 0.3);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 45%;
        left: 60%;
        color: #ff3300;
        z-index: 9999;
        transition: none;
        user-select: none;
        .del {
            padding-top: 20px;
            font-size: 12px;
            color: #222;
        }
        .time {
            margin-top: 30px;
            font-size: 12px;
            color: #f36f4e;
        }
    }
    .seal-box.two {
        left: 10%;
    }
    .sign, .qfz{
        width: 120px;
    }
    .input {
        max-width: 500px;
        min-width: 30px;
        width: auto;
        height: 24px;
        max-height: 24px;
        min-height: 24px;
    }
    .textarea {
        height: 60px;
    }
    textarea {
        resize: auto;
        text-align: center;
    }
}
.left-menu {
    section {
        max-width: 200px;
    }
    .theme-btn {
        margin-bottom: 10px;
        text-align: center;
    }
    .kj-tit {
        padding-left: 16px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        border-left: 2px solid #666;
    }
    .info {
        p {
            text-align: left;
        }
        .tit {
            margin-top: 50px;
            padding-left: 16px;
            font-size: 16px;
            font-weight: 500;
            border-left: 2px solid #666;
        }
        .select-box {
            width: 100%;
        }
    }
    .success {
        margin-top: 2vh;
        font-size: 18px;
        font-weight: 500;
        padding: 16px 0px;
    }
}

.show-pdf {
    min-height: 100%;
    min-height: calc(100vh - 56px);
}
.left-menu {
    text-align: left;
    .tpl-name {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 30px;
        margin-top: 30px;
        border-bottom: 1px solid #e6e6e6;

    }
    &>p {
        font-size: 12px;
        margin-bottom: 10px;

    }
    .active {
        color: #ff8800 !important;
    }
    .favorite {
        text-decoration: underline;
    }
}
.sign-box {
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    li {
        display: flex;
        border-bottom: 1px solid #e6e6e6;
        p {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            padding: 10px;
            border-right: 1px solid #e6e6e6;
            img {
                width: 24px;
                padding: 3px;
            }
            span {
                font-size: 12px;
                padding-top: 5px;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .right-seal {
        .right-box {
            width: 50px;
            min-width: 50px;
        }
        .pdf-content {
            width: 100%;
        }
    }
    
}
</style>