<template>
    <div class="buy">
        <div class="surplus">
            <p>
                <span class="num">18</span>
                <span>剩余可签份数</span>
            </p>
            <p>
                <span class="num used">2</span>
                <span>已用份数</span>
            </p>
        </div>
        
        <ul class="prd-list">
            <li v-for="(item,index) in productList" :key="index">
                <p class="title">签署流量包{{ item.num }}份</p>
                <p class="price">{{ item.price }}/元</p>
                <p class="yj">原价{{ item.yj }}/元</p>
                <p class="btn">立即购买</p>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return {
            productList: [{
                num: '1',
                price: '8',
                yj: '8',
            },{
                num: '10',
                price: '75',
                yj: 80
            },{
                num: '50',
                price: '350',
                yj: 400
            },{
                num: '100',
                price: '650',
                yj: 800
            }]
        }
    }
}
</script>
<style lang="less" scoped>
.buy {
    width: 100%;
}
.surplus {
    width: 100%;
    padding: 30px 0px;
    border-bottom: 1px solid #ddd;
    display: flex;
    p {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .num {
        font-size: 30px;
        color: rgba(9, 117, 225, 1);
    }
    .used {
        color: #ff8800;
    }
}

.prd-list {
    display: flex;
    margin-top: 30px;
    li {
        width: 200px;
        text-align: center;
        border-radius: 10px;
        margin-right: 20px;
        height: 260px;
        border: 1px solid #ffffff;
        padding: 30px 16px 0px 16px;
        
        .title {
            padding-top: 5px;
            color: #222;
        }
        .price {
            font-size: 30px;
            font-weight: 600;
            color: #ff8800;
            padding: 20px 0px;
        }
        .yj {
            text-decoration:line-through;
            font-size: 12px;
            color: #888;
        }
        .btn {
            background: rgba(255,255,255,0.9);
            // color: rgba(9,117,225,1);
            color: #ff8800;
            border: 0px solid rgba(255,255,255,1);
            margin-top: 40px;
            padding: 8px 0px;
            border-radius: 26px;
        }
    }
    li {
        &:hover {
            .btn {
                background: rgba(255,255,255,1);
            }
        }
        &:nth-child(1){
            background: #d2f0ff;
            &:hover {
                box-shadow: 0 0 10px #71c3eb;
                border: 1px solid #71c3eb;
            }
        }
        &:nth-child(2){
            background: #cbf6e6;
            &:hover {
                box-shadow: 0 0 10px #8fddc0;
                border: 1px solid #8fddc0;
            }
        }
        &:nth-child(3){
            background: #d5f3c7;
            &:hover {
                box-shadow: 0 0 10px #9ad57f;
                border: 1px solid #9ad57f;
            }
        }
        &:nth-child(4){
            background: #fff1ce;
            &:hover {
                box-shadow: 0 0 10px #f3ce72;
                border: 1px solid #f3ce72;
            }
        }
    }
}
</style>