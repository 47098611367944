<template>
    <div class="sign">
        <h3 class="push-sign">{{ isEdit ? '编辑模版' : titleText }}</h3>
        <p class="head flex">
            <a-input v-model="signTitle" placeholder="请输入合同主题" />
            <!-- <a-date-picker placeholder="请选择签订截止日期"/> -->
           
        </p>
        <p class="head">
             <a-textarea
                v-model="introduction"
                placeholder="请输入模版简介"
                :auto-size="{ minRows: 2, maxRows: 5 }"
            />
        </p>
         

        <div class="item">
            <p class="title">合同文件</p>
            <div class="up-file">
                <div class="file-item"  v-if="!isEdit">
                    <a-upload-dragger
                        name="file"
                        :multiple="true"
                        :maxCount="1"
                        :beforeUpload='beforeUploadFile'
                    >
                        <p class="ant-upload-drag-icon">
                        <a-icon type="cloud-upload" />
                        </p>
                        <p class="ant-upload-text">
                        添加签署文件
                        </p>
                        <p class="ant-upload-hint">
                        支持格式为：pdf、doc、docx
                        </p>
                    </a-upload-dragger>
                </div>
                <div :class="isEdit ? 'edit-item': 'file-item'">
                    <a-upload-dragger
                        name="file"
                        :multiple="true"
                        :fileList="FjfileList"
                        :beforeUpload='beforEannexUploadFile'
                        :remove="removeFJ"
                    >
                        <p class="ant-upload-drag-icon">
                        <a-icon type="file-sync" />
                        </p>
                        <p class="ant-upload-text">
                        添加签署附件
                        </p>
                        <p class="ant-upload-hint">
                        支持格式为：pdf、doc、docx
                        </p>
                    </a-upload-dragger>
                </div>
                 
                
            </div>
        </div>
       



        <div class="item" v-if="false">
            <p class="title">任务参与人</p>
            <div class="players">
                <div v-for="(item,index) in CY_List" :key="index">
                    <div class="table-content" v-if="item.type == 'QY'">
                        <p>
                            <span class="title">参与方</span>
                            <span class="info">
                                    <a-input placeholder="签署方"/>
                            </span>
                        </p>
                            <p>
                            <span class="title">权限</span>
                            <span class="info">签署</span>
                        </p>
                            <p>
                            <span class="title">参与主体</span>
                            <span class="info">
                                <a-input  placeholder="参与企业"/>
                                </span>
                        </p>
                            <p>
                            <span class="title">经办人账号</span>
                            <span class="info"><a-input placeholder="真实姓名" /> <a-input placeholder="手机号/邮箱" /></span>
                        </p>
                            <p class="yq">
                            <span class="title">签署要求</span>
                            <span class="info">
                                <a-checkbox>企业章</a-checkbox>
                                <a-checkbox>法定代表人章</a-checkbox>
                                </span>
                        </p>
                            <p>
                            <span class="title">操作</span>
                            <span class="info" @click="del_CY(index)">
                                <a>删除</a>
                            </span>
                        </p>
                    </div>
                    <div class="table-content" v-else>
                        <p>
                            <span class="title">参与方</span>
                            <span class="info">
                                <a-input placeholder="签署方"/>
                            </span>
                        </p>
                            <p>
                            <span class="title">权限</span>
                            <span class="info">签署</span>
                        </p>
                            <p>
                            <span class="title">参与主体</span>
                            <span class="info">个人</span>
                        </p>
                            <p>
                            <span class="title">个人账号</span>
                            <span class="info"><a-input placeholder="真实姓名" /> <a-input placeholder="手机号/邮箱" /></span>
                        </p>
                            <p class="yq">
                            <span class="title">签署要求</span>
                            <span class="info">
                                <a-checkbox>手绘章</a-checkbox>
                                <a-checkbox>AI手绘章</a-checkbox>
                                <a-checkbox>模版章</a-checkbox>
                                </span>
                        </p>
                            <p>
                            <span class="title">操作</span>
                            <span class="info" @click="del_CY(index)">
                                <a>删除</a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="add-btn flex">
                <a-button type="dashed" icon="deployment-unit" block @click="add_CY('QY')">添加企业</a-button>
                <a-button type="dashed" icon="user" block @click="add_CY('GE')">添加个人</a-button>
            </div>
        </div>






        <div class="item" v-show="false" v-if="query.from != 'addTpl'">
            <p class="title">抄送人</p>
            <div class="players">
                 <div v-for="(item,index) in CS_List" :key="index">
                    <div class="table-content" v-if="item.type == 'QY'">
                        <p>
                            <span class="title">抄送主体</span>
                            <span class="info">
                                    <a-input placeholder="企业"/>
                            </span>
                        </p>
                        <p>
                            <span class="title">经办人账号</span>
                            <span class="info"><a-input placeholder="真实姓名" /> <a-input placeholder="手机号/邮箱" /></span>
                        </p>
                            <p>
                            <span class="title">操作</span>
                            <span class="info" @click="del_CS(index)">
                                <a>删除</a>
                            </span>
                        </p>
                    </div>
                    <div class="table-content" v-else>
                        <p>
                            <span class="title">抄送主体</span>
                            <span class="info">
                                    <a-input placeholder="个人"/>
                            </span>
                        </p>
                        <p>
                            <span class="title">个人账号</span>
                            <span class="info"><a-input placeholder="真实姓名" /> <a-input placeholder="手机号/邮箱" /></span>
                        </p>
                            <p>
                            <span class="title">操作</span>
                            <span class="info" @click="del_CS(index)">
                                <a>删除</a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="add-btn flex">
                <a-button type="dashed" icon="deployment-unit" block @click="add_CS('QY')">添加企业</a-button>
                <a-button type="dashed" icon="user" block @click="add_CS('GR')">添加个人</a-button>
            </div>
        </div>
        <!-- <a-button type="primary" class="set-p" @click="goSeal">制定位置签署</a-button> -->

        <a-button type="primary"  class="set-p"  @click="goSeal">{{ isEdit ? '编辑模版' : '新增模版' }}</a-button>
        <!-- <a-button type="info"  @click="goSeal('draft')">保存草稿</a-button> -->
       
    </div>
</template>
<script>

// apis
import { uploadFile, queryContractTemplateDetail } from '@/api'
export default {
    data(){
        return {
            titleText: '发起签署',
            introduction: '', // 简介
            CY_List:[], // 参与列表
            query: this.$route.query,

            //合同主题
            signTitle: '',

            FjfileList: [ // 编辑模版的附件列表
            //     {
            //     uid: '-1',
            //     name: 'xxx.png',
            //     status: 'done',
            //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            //     thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            // }
            ],
            addFJlist: [], // 新增模版的附件列表

            fileId: '',
            annexId: '',     // 附件ID

            isEdit: false,
            edidId: '',

            CS_List:[{
                type: 'QY'
            }], // 抄送列表
        }
    },
    mounted(){
        let { type, name, id } = this.$route.query
        if(type == 'edit'){
            this.isEdit = true
            this.edidId = id
            this.signTitle = name
            this.RES_queryContractTemplateDetail()
        }
    },
    methods :{

        // 编辑的时候查询详情信息
        RES_queryContractTemplateDetail(){
            queryContractTemplateDetail({
                data: {
                    id: this.edidId * 1
                }
            }).then(({ data })=>{
                if(data?.data?.contractTempFileList){
                    let fileIdList = data?.data?.contractTempFileList || []
                    fileIdList.forEach(item=>{
                        if(item.fileType == false){
                            this.FjfileList.push({ 
                                uid: item.id,
                                name: item.fileName,
                                status: 'done',
                                url: item.fileUrl,
                                thumbUrl: item.fileUrl
                            })
                             this.addFJlist.push(item.id)
                        }else{
                            this.fileId = item.id
                        }
                    })
                }
                this.introduction = data?.data?.templateDesc
                console.log(data)
            }) 
        },
        // 删除附件文件
        removeFJ(data){
            let newList = []
            let newIdList = []
            this.FjfileList.forEach(item=>{
                if(data.uid != item.uid){
                    newList.push(item)
                    newIdList.push(item.uid)
                }
            })
            this.FjfileList = newList
            this.addFJlist = newIdList
            return false
        },

        add_CY(item){
            this.CY_List.push({
                type: item
            })
        },
        del_CY(index){
            this.CY_List.splice(index,1)
        },


        add_CS(item){
            this.CS_List.push({
                type: item
            })
        },
        del_CS(index){
            this.CS_List.splice(index,1)
        },

        goSeal(){
            // 存进缓存
            localStorage.setItem('signTplInfo',JSON.stringify({
                signTitle: this.signTitle,
                fileId: this.fileId,
                FjID: '',
                introduction: this.introduction
            }))
            if(this.isEdit){
                this.$router.push({
                    path: '/seal',
                    query: {
                        type: 'edit',
                        id: this.edidId,
                        name: this.signTitle,
                        annexId: this.addFJlist.join(',') || ''
                    },
                })
            }else{
                if(!this.signTitle){
                    this.$message.error('请填写合同主题');
                    return;
                }
                if(!this.fileId){
                    this.$message.error('请上传合同文件');
                    return;
                }


                this.$router.push({
                    path: '/seal',
                    query:{
                        fileId: this.fileId,
                        annexId: this.addFJlist.join(',') || ''
                    }
                })
            }


            // if(type == 'add'){
                
            // }else{
            //     // 请求接口保存草稿
            //     let { selectOrg = {} } = this.$store.getters.getOrg || {}
            //     addContractTemplate({
            //         data:{
            //             accountId: localStorage.getItem('orgAccountId'),
            //             templateName: this.signTitle,
            //             isTemp: 0,
            //             contractTempFileList: [{
            //                 id: this.fileId
            //             }],
            //         }
            //     }).then(({ data })=>{
            //         if(data?.data){
            //             this.$router.push({
            //                 path: '/seal',
            //                 query:{
            //                     fileId: this.fileId,
            //                     annexId: this.annexId
            //                 }
            //             })
            //         }
                    
            //     })
            // }

            
        },

        /** 上传签署文件 */
        beforeUploadFile(file){
            let fromData = new FormData()
            fromData.append('fileType', true)
            fromData.append('file', file)
            // fromData.append('accountId', localStorage.getItem('orgAccountId'))
            // fromData.append('userId', 100000001)
            // fromData.append('reqParam', '{}')
            uploadFile({
                type: 'from',
                data: fromData,
                headers: {
                    'Content-Type': 'multipart/form-data',//含有附件的表单提交的Content-Type
                    // 'accountId': localStorage.getItem('orgAccountId')
                    // 'Content-Type': 'application/x-www-form-urlencoded'//普通表单提交的Content-Type
                },
            }).then(({ data })=>{
                let res = data?.data || {}
                this.fileId = res.id
            })
            return false
        },

        /** 上传附件 */
        beforEannexUploadFile(file){
            console.log(file)
            if(this.FjfileList.length >= 10){
                this.$message.error('最多上传10个附件');
                return
            }
            let fromData = new FormData()
            fromData.append('fileType', false)
            fromData.append('file', file)
            uploadFile({
                type: 'from',
                data: fromData,
                headers: {
                    'Content-Type': 'multipart/form-data'//含有附件的表单提交的Content-Type
                },
            }).then(({ data })=>{
                let res = data?.data || {}
                //this.annexId = res.id
                this.addFJlist.push(res.id)
                file.uid = res.id
                this.FjfileList.push(file)
            })
            return false
        }

    }
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
.sign {
    padding: 16px;
}
.head {
    margin-bottom: 20px;
    input, textarea {
        width: 30%;
        margin-right: 20px;
    }
}

.name {
    width: 45%;
    border: 1px solid rgba(9,117,225,0.3);
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 30px;
    padding: 5px 20px;
    margin-right: 20px;
}
.item {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 10px 20px;
    &>.title {
        margin-top: 5px;
        margin-bottom: 10px;
        line-height: 24px;
        padding-left: 10px;
        border-left:5px solid #1063f1;
        font-weight: 500;
    }
    .up-file {
        display: flex;
        padding-bottom: 30px;
        justify-content: space-between;
        align-items: flex-start;
        .file-item {
            width: calc(50% - 10px);
        }
        .edit-item{
            width: 100%;
        }
        
    }
    .add-btn {
        justify-content: space-between;
        button {
            width: 45%;
        }
    }
}
.table-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    &>p {
        display: flex;
        flex-direction: column;
        width: 100%;
        &>.title {
            line-height: 24px;
            font-weight: 500;
            padding: 5px 10px;
            background: #eee;
            width: 100%;
            border-bottom: 1px solid#ddd;
        }
        .info {
            padding: 5px 10px;
            padding-right: 20px;
            display: flex;
            align-items: center;
        }
    }
    .yq {
        width: 300px;
        min-width: 300px;
    }
}
.set-p {
    margin-right: 30px;
}
</style>