<template>
    <div class="help">
        <div class="search">
            <input type="text" placeholder="请输入搜索的问题">
            <p class="theme-btn">搜索</p>
        </div>

        <div class="help-content">
            <div class="item">
                <p>快速入门</p>
                <ul>
                    <li v-for="(item,index) in quickList" :key="index">{{ item }}</li>
                </ul>
            </div>
            <div class="item">
                <p>热门问题</p>
                <ul>
                    <li v-for="(item,index) in hotList" :key="index">{{ item }}</li>
                </ul>
            </div>
            <div class="item">
                <p>场景化操作</p>
                <ul>
                    <li v-for="(item,index) in actionList" :key="index">{{ item }}</li>
                </ul>
            </div>
        </div>

        <div class="customer">
            <div>
                在线客服
                 <img src="@/assets/image/QR.png"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            quickList:[
                '个人用户快速入门',
                '企业用户快速入门',
                '电子合同的法律依据',
                '电子签名的实现原理',
                '核心资质'      
            ],

            hotList:[
                '如何进行个人实名认证',
                '如何进行企业实名认证',
                '如何进行企业管理员转授',
                '如何下载签署完成的合同',
                '合同签署时，提示“无权访问该文件"',
            ],
            actionList:[
                '如何进行合同解约',
                '如何进行相对方管理',
                '合同如何重新发起可以保留之前的填写内容',
                '批量发起时如何跳过填写步骤',
                '如何实现企业自动落印',
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.help {
    width: 100%;
    background: #fcfcfc;
}
.search {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
        width: 380px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #0975e14d;
    }
}
.help-content {
    padding: 0px 10vw;
    padding-top: 10vh;
    display: flex;
    justify-content: space-between;
    .item {
        width: 300px;
        margin: 0px 20px;
        background: #fff;
        padding: 30px 20px 20px 30px;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(9, 117, 225, 0.3);
        p {
            padding-left: 20px;
            border-left: 3px solid #0975e1;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 500;
            color: #222;
        }
        ul {
            li {
                line-height: 30px;
                padding: 10px 0px;
                &:hover {
                    color: #0975e1;
                }
            }
        }
    }
}

.customer {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    div {
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        border-radius:4px ;
        padding: 16px 24px;
        img {
            width: 80px;
            margin-top: 8px;
        }
    }
}
</style>