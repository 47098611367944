<template>
    <div class="add-seal">
        <div class="from">
            <a-form-model-item label="印章名称：">
                <a-input placeholder="请输入印章名称"/>
            </a-form-model-item>

           <a-button>Default</a-button>
        </div>

        <div class="view">dsadsa</div>

    </div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    }
}
</script>
<style lang="less" scoped>
.add-seal {
    padding: 16px;
    display: flex;
}
</style>
