<template>
    <div class="home-page">

        <information @addEnterprise="addEnterprise"></information>
        <div class="home">
            <div class="home-item" v-for="(item,index) in orgInfoList" :key="index">
                <a-icon class="del" type="close" @click="deleteOrg(item)"/>
                <a-result :title="item.orgName" :sub-title="'企业管理员:'+ (item.legalUserInfo.userName || '--') + `;    账号:`+ (item.legalUserInfo.mobile || '--')">
                    <template #icon>
                        <img class="org-ico" src="@/assets/image/my/gongsi.webp" alt="" srcset="">
                    </template>
                    <template #extra>
                        <a-button key="console" type="primary" @click="goOrg(item)">进入企业</a-button>
                        <!-- status 1：待认证，2：认证中，3：已认证 -->
                        <a-button key="buy" v-if="item.status != 3" @click="editOrd(item)">编辑</a-button>
                    </template>
                </a-result>
            </div>
            <!-- <div class="home-item add-org-box">
                <a-button class="addOrg" @click="addEnterprise">
                    + 添加企业
                </a-button>
            </div> -->
        </div>


         <a-modal
            :title="popType == 'add' ? '添加企业' : '编辑企业'"
            cancelText="取消"
			:okText="popType == 'add' ? '确认添加' : '确认编辑'"
            :visible="isAddEPopup"
            @ok="addOrg"
            @cancel="isAddEPopup = false"
            >
            <a-form-model-item label="企业名称">
                <a-input v-model="orgName" placeholder="请填写企业名称"/>
            </a-form-model-item>
            <a-form-model-item label="统一社会信用代码" >
                <a-input v-model="orgCode" placeholder="请填写统一社会信用代码"/>
            </a-form-model-item>
        </a-modal>
    </div>
   
</template>
<script>
// api
import { addOrg, delOrg, updateOrg, queryUserAndOrg } from '@/api'


import information from '@/views/my/information.vue'
export default {
    data(){
        return {
            isAddEPopup: false,
            orgName: '',
            orgCode: '',

            popType: 'add',
            editItem: {},   //编辑的机构的信息
            
        }
    },
    computed:{
        getUserInfo(){
            return this.$store.getters.getUserInfo
        },
        orgInfoList(){
            return this.$store.getters.orgInfoList
        },
        getOrg(){
			return this.$store.getters.getOrg
		},
        getOrgId(){
            return this.$store.getters.getOrgId
        }
    },
    filters:{
        // subTitle(item){
        //     '企业管理员:'+ (getUserInfo.legalUserInfo?.userName || '--') + `;    账号:`+ (getUserInfo.mobile || '--')
        // }
    },
    mounted(){
        // this.$store.dispatch("setShowLeft", false);
        this.$store.dispatch('setState', {
            selectOrg: {},
            OrgId: ''
        })
    },
    destroyed(){
        // this.$store.dispatch("setShowLeft", true);
    },
    methods:{
        // 点击编辑机构
        editOrd(item){
            console.log(item) //
            this.editItem = item
            this.popType = 'edit'
            this.isAddEPopup = true

            // fuzhi
            this.orgName = item.orgName
            this.orgCode = item.orgCode


        },
        // 查询页面信息
        queryPageData(){
            queryUserAndOrg({
                data:{
                    accountId: localStorage.getItem('accountId')
                }
            }).then(({ data })=>{
                let res = data?.data || {}
                // this.orgInfoList = res.orgInfoList || []

                // this.selectOrg = res.orgInfoList[0] || {}


                let storeData = {
                    orgInfoList: res.orgInfoList || [],
                    // selectOrg: {},
                    // OrgId: ''
                }
                
                // let orgId = localStorage.getItem('orgId')
                // if(orgId){
                //     this.orgInfoList.forEach(item=>{
                //         if(item.orgId == orgId){
                //             storeData.selectOrg = item
                //             storeData.OrgId = item.OrgId
                //         }
                //     })
                // }
                this.$store.dispatch('setState', storeData)
            })
        },
         // 点击添加企业
        addEnterprise(){
            this.popType = 'add'
            this.isAddEPopup = true
        },
        // 确认添加企业
        addOrg(){
            if(this.orgName && this.orgCode){
                if(this.popType == 'add'){
                    addOrg({
                        data:{
                            orgName: this.orgName,
                            orgCode: this.orgCode
                        }
                    }).then(({ data })=>{
                        if(data?.data?.msg){
                            this.$message.error(data.data.msg);
                        }
                    }).finally(()=>{
                        this.isAddEPopup = false
                        this.queryPageData()
                    })
                }else{
                    updateOrg({
                        data: {
                            userId: this.$store.getters.getUserInfo.userId,
                            orgId: this.editItem.orgId,
                            orgName: this.orgName,
                            orgCode: this.orgCode,
                            orgLegalIdNumber: this.editItem.legalUserInfo.idNumber,
                            orgLegalName: this.editItem.legalUserInfo.userName
                        }
                    }).then(({ data })=>{
                        if(data?.data?.msg){
                            this.$message.error(data.data.msg);
                        }
                    }).finally(()=>{
                        this.isAddEPopup = false
                        this.queryPageData()
                    })
                }
                
            }else{
                this.$message.error('请填写完整的企业名称和信用代码');
            }
            
        },
        // 删除企业
        deleteOrg(item){
            let that = this
            let getUserInfo = this.getUserInfo || {}
            let resData = {
                userId: this.$store.getters.getUserInfo.userId,
                accountId: getUserInfo.accountId || item.accountId,
                orgId: item.orgId
            }
            this.$confirm({
				title: `你确定要删除：${item.orgName}`,
				okText: '删除',
				cancelText: '取消',
				onOk() {
                    delOrg({
                        data: resData
                    }).then(({ data })=>{
                        if(data?.code == 1){
                            that.$message.success('删除成功');
                        }else{
                            that.$message.error(data?.msg || '删除失败');
                        }
                    }).catch(()=>{
                            that.$message.error('删除失败');
                    }).finally(()=>{
                        that.queryPageData()
                    })
				},
				onCancel() {
					//
				},
			});
        },
        // 点击进入企业
        goOrg(item){
            // if(item.orgId == this.$store.getters.getOrgId){
            //     this.$message.warning(`当前已选择${item.orgName}`)
            // }else{
                 this.$store.dispatch('setState', {
                    selectOrg: item || {},
                    OrgId: item.orgId || ''
                })

                localStorage.setItem('orgId', item.orgId)
                localStorage.setItem('orgAccountId', item.accountId)
                this.$router.push({
                    path: '/work',
                    query: {
                        oid: item.orgId
                    }
                })
            // }
            
        },
    },
    components: {
        information
    }
}
</script>
<style lang="less" scoped>
.home-page {
    width: 70%;
    margin: 0 auto;
    max-width: 1000px;
    min-width: 800px;
}
.home {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .home-item {
        min-height: 290px;
        margin-bottom: 20px;
        padding: 16px 20px;
        border: 1px solid #ececec;
        border-radius: 4px;
        box-shadow: 0px 0px 4px #ececec;
        width: calc(50% - 10px);
        position: relative;
        .org-ico {
            width: 60px;
            height: 60px;
            border-radius: 40px;
            border: 3px solid rgba(16,99,241,0.2);
        }
        .del {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 10px;
        }
    }
}

.add-org-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .addOrg {
        width: 50%;
        height: 80px;
    }
}
</style>