<template>
    <div class="square-page">
        <a-tabs default-active-key="1" @change="changeTable">
            <a-tab-pane :tab="item.categoryName" force-render v-for="(item,index) in tabList" :key="index">
                
            </a-tab-pane>
        </a-tabs>
        <div class="square" v-if="pageList.length > 0">
            <div class="item" v-for="(item,index) in pageList" :key="index">
                <img :src="item.iconUrl || mobanIco">
                <p class="title">{{ item.templateName }}</p>
                <p class="des">{{ item.templateDesc }}</p>
                <p class="info">
                    <span :class="{ active: item.favoriteId }" @click="addCollect(item)"><a-icon type="heart" :theme="item.favoriteId ? 'filled' : 'outlined'"/>{{ item.favoriteId ? '取消收藏' : '收藏' }}</span>
                    <span @click="viewDoc(item)"><a-icon type="file-done"/>预览</span>
                </p>
            </div>       
        </div>
        <div class="no-data" v-else>
            <a-empty v-if="resOK" :image="simpleImage" description="暂无数据"/>
        </div>
        
    </div>
</template>
<script>
// import 食品采购 from '@/assets/image/square/食品采购.jpeg'
// import 标准房租 from '@/assets/image/square/标准房租.jpeg'
// import 产品购销 from '@/assets/image/square/产品购销.jpeg'
// import 二手车买卖 from '@/assets/image/square/二手车买卖.jpeg'
// import 个人借款 from '@/assets/image/square/个人借款.jpeg'
// import 劳动合同 from '@/assets/image/square/劳动合同.jpeg'
// import 设备采购 from '@/assets/image/square/设备采购.jpeg'
// import 安装工程 from '@/assets/image/square/安装工程.jpeg'
import mobanIco from '@/assets/image/square/moban.webp'
import { Empty } from 'ant-design-vue';

// apis 
import { queryPublicContractTemplate, queryContractTemplateCategory, favoriteTemplate, delFavoriteTemplate } from '@/api'
export default {
    data(){
        return {
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            mobanIco,
            selectTabIndex: '',

            tabList:[],

            pageList: [],
            resOK: false,
        }
    },
    computed:{
		getOrg(){
			return this.$store.getters.getOrg
		} 
	},
    mounted(){
        this.queryTabList()
        // this.queryPageData()
    },
    methods: {
        //查询模版分类
        queryTabList(){
            queryContractTemplateCategory({
                data:{
                    accountId: localStorage.getItem('orgAccountId')
                }
            }).then(({ data })=>{
                console.log(data)
                this.tabList = data?.data || []
                if(data?.data){
                    this.selectTabIndex = data.data[0]
                    this.resPageData()
                }

            })
        },
        // 查询模版对应的数据
        resPageData(searchText){
            // let { selectOrg = {} } = this.getOrg || {}
            queryPublicContractTemplate({
                data:{
                    categoryId: this.selectTabIndex.id,
                    templateName: searchText || '',
                    accountId: localStorage.getItem('orgAccountId')
                }
            }).then(({ data })=>{
                if(data?.data){
                    this.pageList = data.data || []
                }
                this.resOK = true
            })
        },
        // 切换tab
        changeTable(index){
            this.selectTabIndex = this.tabList[index]
            this.resPageData()
        },
        // 添加收藏
        async addCollect(item){
            let that = this
            if(item.favoriteId) {
                this.$confirm({
                    title: `你确定要取消收藏：${item.templateName}`,
                    okText: '确定',
                    cancelText: '取消',
                    onOk() {
                        delFavoriteTemplate({
                            data:{
                                id: item.id,
                                accountId: localStorage.getItem('orgAccountId')
                            }
                        }).then(({ data })=>{
                            if(data?.code == 1){
                                that.$message.success('取消收藏成功');
                                that.resPageData()
                            }else{
                                that.$message.error('取消收藏失败');
                            }
                        })
                    }
                })
                
            }else{
                // let { selectOrg = {} } = this.$store.getters.getOrg || {}
                await favoriteTemplate({
                    data:{
                        id: item.id,
                        accountId: localStorage.getItem('orgAccountId')
                    }
                }).then(({ data })=>{
                    if(data?.code == 1){
                        that.$message.success('已收藏');
                    }else{
                        that.$message.error('收藏失败');
                    }
                })
            }
            this.resPageData()

            
            // item.collect = !item.collect
        },
        // 点击预览
        viewDoc(item){
             this.$router.push({
                path: '/seal',
                query: {
                    type: 'view',
                    id: item.id
                },
            })
            console.log(item)
        }
    },
    // watch:{
	// 	getOrg(){
	// 		// this.queryTabList()
    //         // this.resOrgPageData()
	// 	}
	// }
}
</script>
<style lang="less" scoped>
.square-page {
    padding: 16px;
}
.no-data {
   padding-top: 20%;
}
</style>