<template>
    <div class="seal">
        <div class="head-title">
            <a-button v-if="sealInfo.length <= 0" type="primary" class="mr10" @click="AddCreateUserSeal">创建个人印章</a-button>
            <a-button v-if="orgYzList.length <= 0"  type="primary" class="mr10" @click="AddCreateOrgSeal">创建企业印章</a-button>
        </div>

        <p class="title">个人印章：</p>
        <ul class="seal-list">
            <template v-for="(item,index) in orgYzList">
                <li :key="index" v-if="item.userId">
                    <!-- <a class="select" v-if="index==0">
                        <a-icon type="check-square" />默认
                    </a> -->
                    <div>
                        <img :src="item.imgBase64 | setImageData" alt="" srcset="">
                    </div>
                    <p>印章编号：{{ item.id }}</p>
                    <p>创建时间：{{ item.createTm }}</p>
                </li>
            </template>
        </ul>

        <p class="title">企业印章：</p>
        <ul class="seal-list">
            <!-- // eslint-disable-next-line vue/no-use-v-if-with-v-for -->
            <template v-for="(item,index) in orgYzList">
                <li  :key="index" v-if="item.orgId">
                        <!-- <a class="select" v-if="index==0">
                            <a-icon type="check-square" />默认
                        </a> -->
                    <div>
                        <img :src="item.imgBase64 | setImageData" alt="" srcset="">
                    </div>
                    <p>印章编号：{{ item.id }}</p>
                    <p>创建时间：{{ item.createTm }}</p>
                </li>

            </template>
           
        </ul>
    </div>
</template>
<script>
// apis 
import { querySealByOwner, createUserSeal, createOrgSeal } from '@/api'
export default {
    data(){
        return {
            addText: '添加个人印章',
            sealInfo: [],   //个人印章列表

            orgYzList: [],  // 企业印章列表
        }
    },
    computed:{
		getOrg(){
			return this.$store.getters.getOrg
		} 
	},
    filters:{
        setImageData(data){
            if(data.indexOf('data:image/png;base64,')>= 0){
                return data
            }else{
                return `data:image/png;base64,${data}`
            }
        }
    },
    mounted(){
        // this.resPageData()
        this.resOrgPageData()
    },
    methods:{
        /** 查询用户是否有印章 */
        resPageData(){
            let { selectOrg = {} } = this.getOrg || {}
            if(selectOrg.orgId){
                querySealByOwner({
                    data:{
                        accountId: localStorage.getItem('accountId')
                    }
                }).then(({ data })=>{
                    if(data?.data){
                        this.sealInfo = data?.data || []
                    }
                    console.log(data)
                })
            }
        },
        // 查询企业印章
        resOrgPageData(){
            let { selectOrg = {} } = this.getOrg || {}
            if(selectOrg.orgId){
                querySealByOwner({
                    data:{
                        accountId: localStorage.getItem('orgAccountId')
                    }
                }).then(({ data })=>{
                    if(data?.data){
                        this.orgYzList = data?.data || []
                    }
                    console.log(data)
                })
            }
        },
        // 创建个人印章
        AddCreateUserSeal() {
            createUserSeal({
                data:{
                    color: 'RED',
                    type: 'SQUARE',
                }
            }).then(({ data })=>{
                if(data?.data){
                    this.$message.success('创建成功');
                    this.resPageData()
                }else{
                    this.$message.error(data.msg);
                }
            })
        },
        // 创建企业印章
        AddCreateOrgSeal(){
            let { selectOrg = {} } = this.$store.getters.getOrg
            createOrgSeal({
                data:{
                    color: 'RED',
                    type: 'SQUARE',
                    central: 'NONE',
                    orgId: selectOrg.orgId

                }
            }).then(({ data })=>{
                if(data?.data){
                    this.$message.success('创建成功');
                    this.resOrgPageData()
                }else{
                    this.$message.error(data.msg);
                }
            })
        }
    },

    watch:{
		getOrg(){
			this.resPageData()
            this.resOrgPageData()
		}
	}
}
</script>
<style lang="less" scoped>
.seal {
    .head-title {
        display: flex;
    }
    .seal-list {
        display: flex;
        margin-top: 20px;
        li {
            width: 200px;
            height: 270px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            text-align: center;
            margin-right: 20px;
            position: relative;
            .select {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 50px;
                height: 20px;
                font-size: 12px;
                background: #ff8800;
                color: #fff;
                i {
                    margin-right: 3px;
                }
            }
            &:hover{
                box-shadow: 0 0 10px rgba(9, 117, 225, 0.2);
                border: 1px solid rgba(9, 117, 225, .3);    
            }
            div {
                width: 100%;
                height: 180px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 180px;
                }
            }
            
            p {
                text-align: left;
                font-size: 12px;
            }
            .del {
                border-top: 1px solid #ddd;
                text-align: center;
                padding-top: 5px;
                margin-top: 10px;
                color: rgb(9 117 225);
            }
        }
    }
}
.title {
    padding-top: 40px;
    font-size: 16px;
    font-weight: 500;
}
.mr10 {
    margin-right: 10px;
}
</style>