<template>
    <div class="manage">
        <div class="head">
            <span>经办合同</span>
            <a-popover title="扫描二维码发起签署" placement="bottomRight">
                <template slot="content" class="qr">
                    <div class="qr-content">
                        <img src="@/assets/image/QR.jpg"/>
                    </div>
                </template>
                <a-button type="primary" class="mr10">发起合同</a-button>
            </a-popover>
            
        </div>

        <a-tabs default-active-key="1" size="small" @change="changeTable">
            <a-tab-pane key="1" tab="我收到的"></a-tab-pane>
            <a-tab-pane key="2" tab="我发起的" force-render></a-tab-pane>
            <!-- <a-tab-pane key="3" tab="已完成"></a-tab-pane> -->
            <a-tab-pane key="4" tab="待签署"></a-tab-pane>
        </a-tabs>
        <div class="search">
            <div class="search-item">
                <span class="lab">合同信息：</span>
                <input type="text" v-model="contractName" placeholder="搜索合同主题">
                <a-range-picker class="picker" v-model="pickerTime"  @change="changePicker" />
                <a-button type="primary" class="ml10" @click="clickSearch">搜索</a-button>
            </div>
            <!-- <div class="search-item" v-if="[1,2].indexOf(Number(selectTabIndex) || 1) >= 0">
                <span class="lab">合同状态：</span>
                <p>
                    <span v-for="(item,index) in statusList" :key="index" :class="item.index === statusChecked ? 'active' : ''" @click="changeStatusChecked(item)">{{ item.name }}</span>
                </p>
            </div> -->
            <!-- <div class="search-item time">
                <span class="lab">合同时间：</span>
                <a-select @change="changeSelect" class="time-select" placeholder="请选择">
                    <a-select-option value="push">
                        按发起时间筛选
                    </a-select-option>
                    <a-select-option value="success">
                        按完成时间筛选
                    </a-select-option>
                </a-select>
                
            </div> -->
        </div>

        <a-table :columns="columns" :data-source="TableData" >
            <span slot="status" slot-scope="status">
                <a-tag :color="status ? 'volcano' : 'green'"
                >{{ status|statusText }}</a-tag>
            </span>
            <span slot="action" slot-scope="data">
                <a-button type="primary" size="small" @click="clickView(data)"> 查看 </a-button>
            </span>
        </a-table>


        <!-- 查看弹窗 -->
        <a-modal
            :visible="isShowPopup"
            @ok="isShowPopup = false"
            @cancel="isShowPopup = false"
            width="50%"
        >
            <a-descriptions :title="popupInfo.name" size="small" :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }">
                <a-descriptions-item label="发起时间">
                    {{ popupInfo.creatTime }}
                </a-descriptions-item>
                <a-descriptions-item label="完成时间">
                    {{ popupInfo.successTime }}
                </a-descriptions-item>
                <a-descriptions-item label="参与人">
                    {{ popupInfo.partake }}
                </a-descriptions-item>
                <a-descriptions-item label="状态">
                    <a-tag
                    v-for="tag in popupInfo.tags"
                    :key="tag"
                    :color="tag === '进行中' ? 'volcano' : tag == 2 ? 'geekblue' : 'green'"
                >
                    {{ tag.toUpperCase() }}
                </a-tag>
                </a-descriptions-item>
            </a-descriptions>
        </a-modal>

    </div>
</template>
<script>
import dayjs from 'dayjs';

// api
import { queryReceiveContract, querySendContract, queryFinishContract, queryWaitMeContract } from '@/api'


export default {
    data(){
        return {
            pickerTime:[],
            selectTabIndex: '',

            contractName: '',   //合同名称

            statusChecked: '', //选择的合同状态
            statusList:[{
                index: 1,
                name:'填写中',
                checked: false,
            },{
                index: 2,
                name:'签署中',
                checked: false,
            },
            // {
            //     index: 3,
            //     name:'完成签署',
            //     checked: false,
            // },
            {
                index: 0,
                name:'草稿',
                checked: false,
            },{
                index: -1,
                name:'已终止',
                checked: false,
            },{
                index: 4,
                name:'合同审批中',
                checked: false,
            }
            // ,{
            //     index: 5,
            //     name:'完成归档',
            //     checked: false,
            // },{
            //     index: 6,
            //     name:'已过期',
            //     checked: false,
            // },{
            //     index: 9,
            //     name:'已拒绝',
            //     checked: false, //-1=撤回,0=草稿,1=填写中,2=签署中,3=完成签署,4=合同审批中,5=完成归档,6=已过期,9=已拒绝
            // }
            ],

            TableData: [],
            columns:[{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },{
                title: '合同名称',
                dataIndex: 'contractName',
                key: 'contractName',
            },{
                title: '签署方',
                dataIndex: 'contractParticipantList',
                key: 'contractParticipantList',
            },{
                title: '创建时间',
                dataIndex: 'createTm',
                key: 'createTm',
            },{
                title: '截止时间',
                dataIndex: 'endSignTm',
                key: 'endSignTm',
            },{
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                scopedSlots: { customRender: 'status' }, 
            },{
                title: '操作',
                key: 'action',
                scopedSlots: { customRender: 'action' },
            }],


            //查看
            isShowPopup: false,
            popupInfo: {}

        }
    },
    filters:{
        statusText(status){
            // -1=撤回,0=草稿,1=填写中,2=签署中,3=完成签署,4=合同审批中,5=完成归档,6=已过期,9=已拒绝
            let text = ['草稿','填写中','签署中','完成签署','合同审批中','完成归档','已过期']
            if([0,1,2,3,4,5,6].indexOf(status) >= 0){
                return text[status]
            }else{
                if(status == 9){
                    return '已拒绝'
                }else{
                    return '撤回'
                }
            } 
        }
    },
    mounted(){
        this.RES_queryReceiveContract({
            statusRange: [1,2]
        })
        console.log(this.$router.query, 'dsadsads')
    },
    methods: {
        // 我收到的
        RES_queryReceiveContract(agu){
            queryReceiveContract({
                data: agu || {}
            }).then(({ data })=>{
                this.TableData = data?.data || []
                for(let i in this.TableData){
                    this.TableData[i].contractParticipantList = this.TableData[i].contractParticipantList.map(v => v.participantName).join(',')
                }
            })
        },
        // 我发起的
        RES_querySendContract(agu){
            querySendContract({
                data:agu || {}
            }).then(({ data })=>{
                this.TableData = data?.data || []
                for(let i in this.TableData){
                    this.TableData[i].contractParticipantList = this.TableData[i].contractParticipantList.map(v => v.participantName).join(',')
                }
            })
        },

        // 已完成的
        RES_queryFinishContract(agu){
            queryFinishContract({
                data: agu || {}
            }).then(({ data })=>{
                this.TableData = data?.data || []
            })
        },

        // 待签署
        RES_queryWaitMeContract(agu){
            queryWaitMeContract({
                data: agu || {}
            }).then(({ data })=>{
                this.TableData = data?.data || []
            })
        },

        // 切换状态
        changeStatusChecked(item){
            this.statusChecked  = item.index
            this.clickSearch()
        },

        // 点击搜索
        clickSearch(){
            let agu = {
                statusRange: [1,2]
            }
            if(this.statusChecked !== ''){
                agu.status = Number(this.statusChecked)
            }
            if(this.contractName){
                agu.contractName = this.contractName
            }


            // console.log(this.pickerTime[0])
            // console.log(dayjs(this.pickerTime[0]._d).format('YYYY-MM-DD'))
            if(this.pickerTime.length > 0){
                agu.startDate = dayjs(this.pickerTime[0]._d).format('YYYY-MM-DD'),
                agu.endDate = dayjs(this.pickerTime[1]._d).format('YYYY-MM-DD')
            }

            this.changeTable(Number(this.selectTabIndex || 1), agu)
        },

        // 切换tab
        changeTable(index, agu){
            console.log(index,agu)
            let newAgu = Object.assign({
                statusRange: [1,2]
            }, agu || {})
            this.selectTabIndex = index
            this.TableData = []
            if(index == 1){
                this.RES_queryReceiveContract(newAgu)
            }else if(index == 2){
                this.RES_querySendContract(newAgu)
            }else if(index == 3){
                this.RES_queryFinishContract(newAgu)
            }else if(index == 4){
                this.RES_queryWaitMeContract(newAgu)
            }
            
        },


        changeSelect(){

        },
        changePicker(){
            
        },

        //** 点击了查看 */
        clickView(item){
            // let contractFileDtoList = item?.contractFileDtoList[0] || {}
            // localStorage.setItem('fileUrl',`${contractFileDtoList.fileUrl}`)
            this.$router.push({
                path: '/seal',
                query: {
                    type: 'view',
                    id: item.id,
                    from: 'self', // 页面来源
                },
                // params: {
                //     fileUrl: 
                // }
            })
            console.log(item)
        },
        clickPopupInfo(item){
            // this.popupInfo = item
            console.log(item)
        },

        /** 点击催办 */
        clickCB(){
            this.$notification.success({
                message: '催办提醒',
                description:'已经发送了催办信息，请耐心等待！',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        }
        
    },  
}
</script>
<style lang="less" scoped>
.manage {
    padding: 20px 20px;
}
.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: 16px;
        font-weight: 500;
    }
}
.search {
    .search-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding-bottom: 10px;
        .lab {
            width: 100px;
        }
        input {
            width: 300px;
        }
        p {
            display: flex;
            flex-wrap: wrap;
            span {
                padding: 2px 0px;
                width: 70px;
                text-align: center;

                background: #efefef;
                border: 1px solid #ddd;
                border-radius: 2px;
                margin-right: 5px;
                margin-bottom: 5px;
            }
            .active {
                background: rgba(9,117,225,0.15);
                color: rgb(9 117 225);
                border: 1px solid rgba(9,117,225,0.3);
            }
        } 
    }
    .time {
        display: flex;
        align-items: center;
    }
    .time-select {
        width: 180px;
    }
    .picker {
        width: 300px;
        margin-left: 10px;
    }
}

.qr-content {
    text-align: center;
    img {
        width: 160px;
    }
}

</style>