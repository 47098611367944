<template>
	<div class="contract">
		<div class="title">
            <a-button type="primary" class="mr10" @click="clickAdd">添加相对方</a-button>
		</div>
		<div>
			<a-table :columns="columns" :data-source="data">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<span slot="customTitle">姓名</span>

				<span slot="userType" slot-scope="userType">
					<a-tag :color="userType == 1 ? 'volcano' : 'green'"
					>{{ userType == 1 ? '个人' : '企业' }}</a-tag>
				</span>

                <span slot="idNumber" slot-scope="idNumber,data">
                    <span>{{ data.userType == 1 ? idNumber : '--' }}</span>
				</span>

                <span slot="orgCode" slot-scope="orgCode,data">
                    <span>{{ data.userType == 2 ? orgCode : '--' }}</span>
				</span>

                <span slot="agentName" slot-scope="agentName">
					<span>{{ agentName ? agentName : '--' }}</span>
				</span>
				<!-- <span slot="status" slot-scope="status,data">
					<a-switch default-checked :checked="status===0"  @change="clickDJ(data)" />
				</span> -->

				<span slot="action" slot-scope="data">
                    <template v-if="data.verificationTarget">
                        <span>{{ data.userType == 1 ? '已实名' : '已认证' }}</span>
                    </template>
                    <template v-else>
                        <a-button style="margin-left: 8px" type="primary" size="small" @click="clickDEL(data)">删除</a-button>
                        <a-button style="margin-left: 8px" type="primary" size="small" @click="clickEdit(data)">编辑</a-button>
                    </template>
					
				</span>
			</a-table>
		</div>

		<!-- 编辑查看员工弹窗 -->
		<!-- <a-modal
			:visible="viewOrEditPopup"
			cancelText="取消"
			okText="确认"
			@ok="InviteEmployeesOK"
			@cancel="viewOrEditPopup = false"
			width="50%"
		>
			<a-descriptions :title="popupType == 'view' ? '查看': '编辑'">
				<a-descriptions-item label="UserName">Zhou Maomao</a-descriptions-item>
				<a-descriptions-item label="Telephone">1810000000</a-descriptions-item>
				<a-descriptions-item label="Live">Hangzhou, Zhejiang</a-descriptions-item>
				<a-descriptions-item label="Remark">empty</a-descriptions-item>
				<a-descriptions-item
					label="Address"
				>No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China</a-descriptions-item>
			</a-descriptions>
		</a-modal> -->

		<!-- 添加相对方 -->
		<a-modal
			:visible="openInvitePopup"
			cancelText="取消"
			:okText="popupType == 'add' ? '添加' : '保存'"
			:title="popupType == 'add' ? '添加相对方' : '编辑相对方'"
			@ok="openOppOk"
			@cancel="openInvitePopup = false"
			width="28%"
			class="invite-popup"
		>	
            <a-select v-model="opp_type" style="width: 100%" @change="changeOpp" placeholder="请选择相对方类型" >
                <a-select-option value="1">个人</a-select-option>
                <a-select-option value="2">企业</a-select-option>
            </a-select>
			<a-input v-model="opp_phone" type="number" placeholder="请输入相对方电话" />	
			<a-input v-model="opp_name" type="text" placeholder="请输入相对方姓名" />	
			<a-input v-model="opp_agent" type="text" placeholder="请输入代理人名称" />	
			<a-input v-model="opp_IdCardNumber" v-if="opp_type == 1" type="text" placeholder="请输入身份证号" />	
			<a-input v-model="opp_OrgCode" v-else type="text" placeholder="请输入统一社会信用代码" />	
		</a-modal>
	</div>
</template>
<script>
// api 
import { queryContractPartner, addPartner, delPartner, editPartner } from '@/api'

export default {
	data() {
		return {
			columns:[{
				title: 'ID',
				dataIndex: 'id',
				key: 'id',
			},{
				title: '姓名',
				dataIndex: 'userName',
				key: 'userName',
			},{
				title: '创建时间',
				dataIndex: 'createTm',
				key: 'createTm',
			},{
				title: '电话',
				dataIndex: 'userPhone',
				key: 'userPhone',
			},{
				title: '代理人',
				dataIndex: 'agentName',
				key: 'agentName',
				scopedSlots: { customRender: 'agentName' },
			},{
				title: '身份证号',
				dataIndex: 'idNumber',
				key: 'idNumber',
				scopedSlots: { customRender: 'idNumber' },
			},{
				title: '信用代码',
				dataIndex: 'orgCode',
				key: 'orgCode',
				scopedSlots: { customRender: 'orgCode' },
			},{
				title: '类型',
				dataIndex: 'userType',
				key: 'userType',
				scopedSlots: { customRender: 'userType' }, // 1=个人，2=企业
			},{
				title: '操作/状态',
				key: 'action',
				scopedSlots: { customRender: 'action' },
			}],
			data: [],

			//添加相对方
			openInvitePopup: false,
            popupType: 'add',  //add 添加｜ edit 编辑
            editItem: {}, // 编辑的对象

            opp_type: 2,
			opp_phone: '',
			opp_name: '',
            opp_agent: '',
            opp_IdCardNumber: '',
            opp_OrgCode: '',

			
		}
	},
	mounted(){
        this.resPageData()
	},
	methods: {
        // 查询页面参数
        resPageData(){
            let that = this
            queryContractPartner({
                data:{
                    accountId: localStorage.getItem('orgAccountId')
                }
            }).then(({ data })=>{
                that.data = data?.data || []
            })
        },

        // 切换类型的时候
        changeOpp(){
            this.opp_IdCardNumber = ''
            this.opp_OrgCode = ''
        },

        // 点击添加按钮
        clickAdd(){
            this.popupType = 'add'
            this.openInvitePopup = true
            this.opp_type = '2'
            this.opp_phone = ''
            this.opp_name = ''
            this.opp_agent = ''
            this.opp_IdCardNumber = ''
            this.opp_OrgCode = ''
        },

        // 点击编辑
        clickEdit(data){
            this.popupType = 'edit'
            this.openInvitePopup = true
            this.opp_type = `${data.userType}`
            this.opp_phone = data.userPhone
            this.opp_name = data.userName
            this.opp_agent = data.agentName || ''
            this.editItem = data
            this.opp_IdCardNumber = data.idNumber || ''
            this.opp_OrgCode = data.orgCode || ''
        },

        // 添加相对方
        openOppOk(){
            if(!this.opp_name || !this.opp_phone){
                this.$message.error('请填写相对方姓名和电话');
                return;
            }
            if(this.opp_type == 2 && !this.opp_agent){
                this.$message.error('请填写代理人名称');
                return;
            }

            if(this.popupType == 'add'){
                addPartner({
                    data:{
                        accountId: localStorage.getItem('orgAccountId'),
                        userType: Number(this.opp_type),
                        userName: this.opp_name,
                        userPhone: this.opp_phone,
                        agentName: this.opp_agent,
                        idNumber: this.opp_IdCardNumber,
                        orgCode: this.opp_OrgCode
                    }
                }).then(({ data })=>{
                    if(data?.code == 1){
                        this.$message.success('添加成功');
                        this.resPageData()
                    }else{
                        this.$message.error('添加失败');
                    }
                })
            }else{
                editPartner({
                    data:{
                        accountId: localStorage.getItem('orgAccountId'),
                        userType: Number(this.opp_type),
                        userName: this.opp_name,
                        userPhone: this.opp_phone,
                        agentName: this.opp_agent,
                        id: this.editItem.id,
                        idNumber: this.opp_IdCardNumber,
                        orgCode: this.opp_OrgCode
                    }
                }).then(({ data })=>{
                    if(data?.code == 1){
                        this.$message.success('编辑成功');
                        this.resPageData()
                    }else{
                        this.$message.error('编辑失败');
                    }
                })
            }
            
            this.openInvitePopup = false
        },



        // 删除相对方
        clickDEL(item){
            let that = this
            this.$confirm({
                title: `你确定要删除相对方：${item.userName}`,
                content: `电话：${item.userPhone}`,
                okText: '删除',
                cancelText: '取消',
                onOk: () => {
                    delPartner({
                        data:{
                            accountId: localStorage.getItem('orgAccountId'),
                            id: item.id
                        }
                    }).then(({ data })=>{
                        if(data?.code == 1){
                            that.$message.success('删除成功');
                            that.resPageData()
                        }else{
                            that.$message.error('删除成功');
                        }
                    })
                },
            });
        }
    }
}
</script>
<style lang="less" scoped>
.title {
	display: flex;
	padding-bottom: 16px;
}
.contract {
	width: 100%;
	padding: 16px;
}

.search-list {
	min-height: 400px;
	overflow: scroll;
}
.IE-list {
	margin-top: 20px;
}
.IE-checkbox {
	margin-right: 10px;
}
.invite-popup {
	input, .ant-select  {
		margin-bottom: 10px;
	}
}
</style>