<template>
    <div class="square-page">
        <div class="square" v-if="pageList.length > 0">
            <div class="item" v-for="(item,index) in pageList" :key="index">
                <img :src="item.iconUrl || mobanIco">
                <p class="title">{{ item.templateName }}</p>
                <p class="des">{{ item.templateDesc }}</p>
                <p class="info">
                    <span class="active" @click="deleteDoc(item)"><a-icon type="heart" theme='filled'/>取消收藏</span>
                    <span @click="viewDoc(item)"><a-icon type="file-done"/>预览</span>
                </p>
            </div>       
        </div>
        <div class="no-data" v-else>
            <a-empty description="暂无数据" :image="simpleImage"/>
        </div>
        
    </div>
</template>
<script>
import mobanIco from '@/assets/image/square/moban.webp'
import { Empty } from 'ant-design-vue';


// apis 
import { queryFavoriteTemplate, delFavoriteTemplate } from '@/api'
export default {
    data(){
        return {
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            mobanIco,
           
            selectTabIndex: '',

            tabList:[],

            pageList: [],

            resOK: false,
        }
    },
    mounted(){
        this.resPageData()
        // this.queryPageData()
    },
    methods: {
        // 查询模版对应的数据
        resPageData(){
            // let { selectOrg = {} } = this.$store.getters.getOrg || {}
            queryFavoriteTemplate({
                data:{
                    accountId: localStorage.getItem('orgAccountId')
                }
            }).then(({ data })=>{
                if(data?.data){
                    this.pageList = data.data || []
                }
            })
        },
        // 点击删除
        deleteDoc(item){
            let that = this
            this.$confirm({
                    title: `你确定要取消收藏：${item.templateName}`,
                    okText: '确定',
                    cancelText: '取消',
                    onOk() {
                        // let { selectOrg = {} } = this.$store.getters.getOrg || {}
                        delFavoriteTemplate({
                            data:{
                                id: item.id,
                                accountId: localStorage.getItem('orgAccountId')
                            }
                        }).then(({ data })=>{
                            if(data?.code == 1){
                                that.$message.success('取消成功');
                                that.resPageData()
                            }else{
                                that.$message.error('取消失败');
                            }
                        })
                    }
            })
            
        },
        // 点击预览
        viewDoc(item){
             this.$router.push({
                path: '/seal',
                query: {
                    type: 'view',
                    id: item.id
                },
            })
            console.log(item)
        }
    }
}
</script>
<style lang="less" scoped>
.square-page {
    padding: 16px;
}
.no-data {
   padding-top: 20%;
}
</style>