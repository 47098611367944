<template>
    <div class="org">
    
        <a-result title="Great, we have done all the operations!">
            <template #icon>
                 <a-icon type="home" />
            </template>
            <template #extra>
                <a-button key="console" type="primary">Go Console</a-button>
                <a-button key="buy">Buy Again</a-button>
            </template>
        </a-result>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
.org {
    width: 100%;
}
</style>