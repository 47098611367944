<template>
    <div class="respwd-page">
        <img class="logo" :src="LOGO" alt="" srcset="">
        <div class="box respwd">
                <div class="content">
                    <p class="title">找回密码</p>
                    <STEP :step="step" @changeStep="changeStep"></STEP>

                    <div class="input-box" v-if="step == 1">
                        <LgInput
                            label="请输入手机号/邮箱"
                            class="ipt-item"
                            type="phone"
                            maxlength="20"
                            :value="phone"
                            :rightText="getCodeText"
                            @input="changePhone"
                            :inputStyle="inputStyle"
                        >
                            <template v-slot:right>
                                <span :class="['right-text', { sendCode: isSendCode }]" @click="clickSendCode">{{ getCodeText }}</span>
                            </template>
                        </LgInput>
                        <LgInput
                            label="动态验证码"
                            class="ipt-item"
                            type="text"
                            :value="code"
                            maxlength="20"
                            @input="changeCode"
                            :inputStyle="inputStyle"
                        ></LgInput>
                    </div>

                    <div class="input-box" v-if="step == 2">
                        <LgInput
                            label="登录密码"
                            class="ipt-item"
                            type="password"
                            maxlength="12"
                            :value="newPassword"
                            @blur="blueNewPwd"
                            @input="changeNewPwd"
                        ></LgInput>
                        <LgInput
                            label="确认密码"
                            class="ipt-item"
                            type="password"
                            maxlength="12"
                            :value="newPassword2"
                            @blur="blueNewPwd2"
                            @input="changeNewPwd2"
                        ></LgInput>
                    </div>
                    <div class="input-box success" v-if="step == 3">
                        <img :src="SUCCES">
                        <p>重置密码成功</p>
                    </div>
                    <div class="input-box next-box">
                        <div class="login-btn" @click="clickNext">{{ nextText  }}</div>
                        <p v-if="step != 3" class="go-login" @click="goLogin">前往登录</p>
                    </div>
                    
                    
                </div>
        </div>
    </div>
</template>
<script>

import LOGO from '@/assets/image/login/logo.png'
import SUCCES from '@/assets/image/login/success.png'

//components
import STEP from '@/components/step.vue'
import  LgInput from '@/components/LgInput.vue'

// api
import { getRegistSmsCode, resetPasswd } from '@/api'

export default {
    data(){
        return {
            LOGO,
            SUCCES,
            step: 1, // 步骤
            getCodeText: '获取验证码',  // 获取验证码的文字
            isSendCode: false,
            inputStyle:{
                paddingLeft:'1.45rem'
            },

            phone: '',
            code: '',
            newPassword: '',
            newPassword2: ''

        }
    },
    computed: {
        nextText(){
            if(this.step < 3){
                return '下一步'
            }else{
                return '前往登录'
            }
        },
        /** 验证两次密码是否一致 */
        PwdIdentical(){
            if(this.newPassword && this.newPassword == this.newPassword2){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        /** 点击获取验证码 */
        clickSendCode() {
            if(!this.isSendCode){
                if(!this.phone){
                    this.$message.error('请输入正确的手机号');
                    return
                }
                this.isSendCode = true
                let that = this
                getRegistSmsCode({
                    params:{
                        mobile: this.phone
                    }
                }).then(({ data })=>{
                    if(data.code == 1){
                        this.$message.success('验证码已发送');
                        let time = 60
                        this.getCodeText = `60秒后重新获取`
                        let T = setInterval(()=> {
                            time = --time
                            that.getCodeText = `${time}秒后重新获取`
                            if( time <= 0 ){
                                clearInterval(T)
                                that.isSendCode = false
                                that.getCodeText = '获取验证码'
                            }
                        },1000)
                    }else{
                        this.$message.error('验证码获取失败');
                        this.isSendCode = false
                    }
                })
            }
        },
        /** 点击下一步 */
        clickNext(){
            if(this.step >= 3){
                this.goLogin()
            }else{
                if(this.step == 1){
                    if(!this.phone || !this.code){
                        this.$message.error('请填写手机号/邮箱或验证码');
                        return
                    }
                    this.step = this.step + 1
                }else if (this.step == 2){
                    if(!this.newPassword){
                        this.$message.error('请输入新密码');
                        return
                    }
                    if(this.newPassword && this.newPassword.length < 8){
                        this.$message.error('密码至少为8-12位，字母+数字+特殊符号');
                        return
                    }
                    if(!this.PwdIdentical){
                        this.$message.error('两次密码不一致');
                        return
                    }
                    
                    resetPasswd({
                        data: {
                            mobile: this.phone,
                            smsCode: this.code,
                            passwd: this.newPassword
                        }
                    }).then(({ data })=>{
                        if(data.code ==1){
                            this.step = this.step + 1
                        }else{
                            this.$message.error(data.msg || '重置密码失败');
                        }
                        
                    })
                }
            }
            
        },
        blueNewPwd(){
            if(this.newPassword && this.newPassword.length < 8){
                this.$message.error('密码至少为8-12位，字母+数字+特殊符号');
            }
        },
        blueNewPwd2(){
            if(this.newPassword != this.newPassword2){
                this.$message.error('两次密码不一致');
            }
        },
        /** 跳转到登录 */
        goLogin(){
            this.$router.push({
                name: 'login'
            })
        },
        changeStep(item){
            if(item.index < this.step){
                this.step = item.index
            }
        },
        changePhone({ value }){
            this.phone = value
        },
        changeCode({ value }){
            this.code = value
        },
        changeNewPwd({ value }){
            this.newPassword = value
        },
        changeNewPwd2({ value }){
            this.newPassword2 = value
        }

    },
    components: {
        STEP,
        LgInput
    }
}
</script>
<style lang="less" scoped>
@pd: 25%;
.respwd-page {
    background-image: url(../assets/image/login/login_bg.png);
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    .logo {
        position: absolute;
        width: 60px;
        left: 100px;
        top: 110px;
        height: auto;
    }
}
.respwd {
    background: #fff;
    width: 1px;
    height: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 9999;
    .content {
        width: 1200px;
        height: 700px;
        margin-left: -600px;
        margin-top: -350px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 0px 12px rgba(50, 115, 120 , .15);
        .title {
            font-size: 22px;
            color: #004043;
            padding: 73px @pd 50px @pd;
        }
    }
}

.input-box {
    padding: 50px @pd 0px @pd;
    .ipt-item {
        margin-bottom: 20px;
    }
    .sendCode {
        color: #c4c4c4;
    }
    .login-btn {
        background: #00908F;
        color: #fff;
        font-size: 20px;
        padding: 20px 0px;
        margin-top: 50px;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0px 5px 10px rgba(0, 144, 143, 0.50);
    }
}
.success {
    text-align: center;
    img {
        width: 70px;
    }
    p {
        color: #004043;
        font-size: 22px;
        padding: 24px 0px 40px 0px;
    }
}
.next-box{
    padding: 0px @pd 0px @pd;
    .go-login {
        padding: 20px 0px;
        color: #007FEF;
        font-size: 18px;
        text-decoration: underline;
    }
}
</style>